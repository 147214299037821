import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const MicrosoftLoginRedirect = () => {
  const history = useHistory();

  useEffect(() => {
    const clientId = 'd8c3d816-86ca-406e-8f95-5f602c5d8f1c';
    const redirectUri = 'https://www.sellwisely.ai/ms-callback/';
    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize`;
    const params = new URLSearchParams({
      client_id: clientId,
      response_type: 'code',
      redirect_uri: redirectUri,
      scope: 'User.Read offline_access Calendars.Read',
      response_mode: 'query'
    });

    window.location.href = `${authUrl}?${params.toString()}`;
  }, [history]);

  return <div>Redirecting to Microsoft login...</div>;
};

export default MicrosoftLoginRedirect;
