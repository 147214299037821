import React, { useState } from 'react';
import { Button, Flex, FormControl, FormLabel, Input, InputGroup, InputLeftElement, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import { EmailIcon, PhoneIcon } from '@chakra-ui/icons';
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

const ContactPage = () => {
  const authBg = useColorModeValue('white', 'navy.900');
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');

  const requestDemoUrl = "https://inwise-node-functions2.azurewebsites.net/api/handle-contact-cta?code=G0mbWB1Q6VRvIoBcB9_cz_VfmyE5E-D84BXGrkyFaxTAAzFuP9HQWQ%3D%3D";

  const isFormValid = () => {
    return name.trim() !== '' && email.trim() !== '' && message.trim() !== '';
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(requestDemoUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        console.error('Network response was not ok');
      }
      const data = await response.json();
      console.log('Request demo success:', data);
    } catch (error) {
      console.error('Error requesting demo:', error);
    }
  };

  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} minHeight="100vh">
      <NavBar />
      <Flex alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} flexDir='column' pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>Get in touch</Text>
        </Flex>
        <Flex flexDir={'column'}>
          <Text fontSize={'20px'}>We're here to listen! Reach out with any questions, feedback, or just to say hi - we love hearing from you.</Text>
          <Text fontSize={'20px'}>We are open M-F 9am to 5pm PST. We will respond within 12 hours.</Text>
        </Flex>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: '100%', md: '920px' }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: 'auto', lg: 'unset' }}
          me='auto'
          mb={{ base: '20px', md: 'auto' }}>
          <FormControl pt='50px' w={'100%'}>
            <Flex justifyContent={'space-between'}>
              <Flex flexDir={'column'} w={'50%'} pr='20px' >
                <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                  Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <Input
                  isRequired={true}
                  fontSize='sm'
                  ms={{ base: '0px', md: '4px' }}
                  placeholder='John Carter'
                  mb='24px'
                  size='lg'
                  type={'text'}
                  variant='outline'
                  onChange={e => setName(e.target.value)}
                />
              </Flex>
              <Flex flexDir={'column'} w={'50%'} pl='20px'>
                <FormLabel display='flex' ms='4px' fontSize='sm' fontWeight='500' color={textColor} mb='8px'>
                  Email<Text color={brandStars}>*</Text>
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <EmailIcon color='gray.300' />
                  </InputLeftElement>
                  <Input
                    isRequired={true}
                    variant='outline'
                    fontSize='sm'
                    ms={{ base: '0px', md: '0px' }}
                    type='email'
                    placeholder='example@email.com'
                    mb='24px'
                    fontWeight='500'
                    size='lg'
                    onChange={e => setEmail(e.target.value)}
                  />
                </InputGroup>
              </Flex>
            </Flex>
            <Flex justifyContent={'space-between'}>
              <Flex flexDir={'column'} w={'50%'} pr='20px' >
                <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                  Phone
                </FormLabel>
                <InputGroup>
                  <InputLeftElement pointerEvents='none'>
                    <PhoneIcon color='gray.300' />
                  </InputLeftElement>
                  <Input
                    isRequired={false}
                    fontSize='sm'
                    ms={{ base: '0px', md: '4px' }}
                    placeholder='(123) 456 - 789'
                    mb='24px'
                    size='lg'
                    type={'tel'}
                    variant='outline'
                    onChange={e => setPhone(e.target.value)}
                  />
                </InputGroup>
              </Flex>
              <Flex flexDir={'column'} w={'50%'} pl='20px' >
                <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                  Company
                </FormLabel>
                <Input
                  isRequired={false}
                  fontSize='sm'
                  ms={{ base: '0px', md: '4px' }}
                  placeholder='Meta'
                  mb='24px'
                  size='lg'
                  type={'text'}
                  variant='outline'
                  onChange={e => setCompany(e.target.value)}
                />
              </Flex>
            </Flex>
            <Flex flexDir={'column'} w={'100%'}>
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color={textColor} display='flex'>
                Message
              </FormLabel>
              <Textarea
                isRequired={true}
                fontSize='sm'
                ms={{ base: '0px', md: '4px' }}
                placeholder='Please type your message here'
                mb='24px'
                size='lg'
                height={'200px'}
                variant='outline'
                minLength={20}
                maxLength={500}
                onChange={e => setMessage(e.target.value)}
                sx={{
                  '&::placeholder': {
                    lineHeight: '1.2',
                    paddingTop: '8px',
                  },
                }}
              />
            </Flex>
            <Flex justifyContent='flex-end' mt='24px'>
              <Button
                variant={'brand'}
                borderWidth={3}
                fontWeight='500'
                w='200px'
                h='50px'
                onClick={handleSubmit}
                isDisabled={!isFormValid()}>
                Send Message
              </Button>
            </Flex>
          </FormControl>
        </Flex>
      </Flex>
      <FooterNavBar />
    </Flex>
  );
};

export default ContactPage;
