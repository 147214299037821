import { useState } from "react";
import {
  Flex,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import { BackButton, NextButton } from "views/common/common";

export default function FindCompany(props: {
  onSubmit: (formData: any) => Promise<void>;
  onReturn: () => void;
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { onSubmit, onReturn } = props;

  const [formData, setFormData] = useState({
    companyName: '',
  });

  const handleInputChange = (name: string, value: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      const response = await onSubmit(formData);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  return (
    <Card p='30px'>
      <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
        Find Company
      </Text>
      <Flex direction='column' w='100%'>
        <SimpleGrid columns={{ base: 2, md: 1 }} gap='20px'>
          <Stack direction='row' gap='20px'>
            <InputField
              mb='0px'
              id='name'
              placeholder='eg. Google'
              value={formData.companyName}
              label='Company Name *'
              onChange={(value: any) => handleInputChange('companyName', value.target.value)
              }
            />
          </Stack>
        </SimpleGrid>
        <Flex justify='space-between' mt='24px'>
          <BackButton onClick={onReturn} buttonText={'Cancel'} />
          <NextButton
            onClick={handleSubmit}
            buttonText={'Search'}
            disabled={formData.companyName.length < 1}
            ms={'auto'} />
        </Flex>
      </Flex>
    </Card>
  );
}
