import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "d15ab202-1e56-4c07-b0dd-78285439f89c",
    authority: "https://login.microsoftonline.com/common",
    redirectUri: "https://www.sellwisely.ai/login"
    // redirectUri: "http://localhost:3000/login"
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
  scopes: ["User.Read", "Calendars.Read", "offline_access"]
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};

