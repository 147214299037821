import { Link as ReactLink } from 'react-router-dom';
import {
  List,
  ListItem,
  Text,
  useColorModeValue,
  Avatar,
  Flex,
  Box,
  Button,
  LinkBox,
  LinkOverlay,
  Tooltip,
} from '@chakra-ui/react';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import Card from 'components/card/Card';
import { Prospect } from 'views/prospects/Prospect';
import { useState } from 'react';

//TODO(KRIS) : move this up in the dir
export default function ProspectListCard(props: {
  prospects: Prospect[],
  dealId?: string,
  headerText: string,
  variant: 'dealOverview' | 'accountOverview',
  [x: string]: any
}) {
  const { prospects, dealId, headerText, variant, ...rest } = props;
  const titleColor = useColorModeValue('navy.700', 'white');
  const nameColor = useColorModeValue('secondaryGray.900', 'white');
  const roleColor = useColorModeValue('secondaryGray.600', 'white');
  if (!prospects) return null;

  localStorage.setItem("dealId", dealId);
  const Header = () =>
    variant == 'dealOverview' ?
      <Flex justifyContent={'space-between'}>
        <Text color={titleColor} fontSize='lg' fontWeight='700' mb='10px'>
          {headerText}
        </Text>
        <NavLink to={{
          pathname: '/prospects/create',
          state: {
            routeName: 'Add new prospect'
          }
        }}>
          <Flex
            borderColor={'brand.500'}
            borderWidth='1px'
            color={'brand.500'}
            p='0px 0px'
            fontSize='2xl'
            fontWeight='extrabold'
            mb='12px'
            borderRadius={'50%'}
            h='29px'
            w='29px'
            textAlign={'center'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            +
          </Flex>
        </NavLink>
      </Flex>
      :
      <Text color={titleColor} fontWeight={'bold'} fontSize={'18px'} mb='20px'>
        {headerText}
      </Text>

  const history = useHistory();
  const handleAddProspect = (fullName: string) => {
    const firstName = fullName.split(" ")[0];
    const lastName = fullName.split(" ")[1] || null;

    localStorage.setItem("newProspectFirstName", firstName);
    localStorage.setItem("newProspectLastName", lastName);
    history.push(`/prospects/create`);
  }

  const [isHovered, setIsHovered] = useState(false);

  return (

    <Card p='20px' {...rest} >
      <Header />
      <List spacing={3}>
        {prospects.map((prospect, key) => (
          <ProspectListItem prospect={prospect} handleAddProspect={handleAddProspect} key={key} />
        ))}
      </List>
    </Card>
  );
}

function ProspectListItem(props: {
  prospect: Prospect,
  handleAddProspect: (name: string) => void,
  [x: string]: any
}) {
  const { prospect, handleAddProspect, ...rest } = props;
  const [isHovered, setIsHovered] = useState(false);
  const nameColor = useColorModeValue('secondaryGray.900', 'white');
  const roleColor = useColorModeValue('secondaryGray.600', 'white');
  return (
    <LinkBox as='article' w={'100%'} {...rest} >
      <ListItem>
        {prospect._id ?
          <LinkOverlay as={ReactLink} to={{
            pathname: `/prospects/overview/${prospect._id}`,
            state: {
              routeName: `${prospect.name}`
            }
          }}>
            <Flex alignItems='center' pl="11px" w={'200px'}>
              <Avatar w="34px" h="34px" mr="14px" src={prospect.pictureLink} />
              <Box>
                <Text color={nameColor} fontSize='md' fontWeight='700'>
                  {prospect.name || prospect}
                </Text>
              </Box>
            </Flex>
          </LinkOverlay>
          :
          <Flex alignItems='center' justifyContent={'space-between'} pl="11px" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Flex>
              <Text color={nameColor} fontSize='md' fontWeight='700'>
                {(typeof prospect === 'string' || prospect instanceof String) ? prospect : prospect.name}
              </Text>
            </Flex>
            <Tooltip label='Add to prospects'>
              <Button size={'xs'} fontSize={'md'} onClick={() => handleAddProspect(prospect.name)} variant={'brand'} display={isHovered ? 'block' : 'none'}>+</Button>
            </Tooltip>
          </Flex>}
      </ListItem>
    </LinkBox>
  );
}
