import React, { useState } from 'react';
import { Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react';
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

const TermsPage = () => {

  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} pr={'100px'}>
      <NavBar />
      <Flex flexDir={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>Terms of Use</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>These terms and conditions outline the rules and regulations for the use of our platform.</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>By accessing this website we assume you accept these terms and conditions. Do not continue to use Inwise AI if you do not agree to take all of the terms and conditions stated on this page.</Text>
        </Flex>
      </Flex>
      <Flex flexDir={'column'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'} pb={'100px'}>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Cookies</Text>
          <Text>
            We employ the use of cookies. By accessing Inwise AI, you agreed to use cookies in agreement with the Inwise AI's Privacy Policy.
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>License</Text>
          <Text mb={'15px'}>

            Unless otherwise stated, Inwise AI and/or its licensors own the intellectual property rights for all material on Inwise AI. All intellectual property rights are reserved. You may access this from Inwise AI for your own personal use subjected to restrictions set in these terms and conditions.

            You must not:
          </Text>
          <UnorderedList pl={'20px'}>
            <ListItem>Republish material from Inwise AI</ListItem>
            <ListItem>Sell, rent or sub-license material from Inwise AI</ListItem>
            <ListItem>Reproduce, duplicate or copy material from Inwise AI</ListItem>
            <ListItem>Redistribute content from Inwise AI</ListItem>
          </UnorderedList>

        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Comments</Text>
          <Text>Inwise AI reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Hyperlinking to our Content</Text>
          <Text>The following organizations may link to our Website without prior written approval:</Text>
          <UnorderedList pl={'20px'}>
            <ListItem>Government agencies</ListItem>
            <ListItem>Search engines</ListItem>
            <ListItem>News organizations</ListItem>
            <ListItem>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses</ListItem>
            <ListItem>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</ListItem>
          </UnorderedList>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>iFrames</Text>
          <Text>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Content Liability</Text>
          <Text>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Reservation of Rights</Text>
          <Text>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it's linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Removal of links from our website</Text>
          <Text>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Disclaimer</Text>
          <Text> To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</Text>
        </Flex>
        <UnorderedList pl={'20px'}>
          <ListItem>limit or exclude our or your liability for death or personal injury</ListItem>
          <ListItem>limit or exclude our or your liability for fraud or fraudulent misrepresentation</ListItem>
          <ListItem>limit any of our or your liabilities in any way that is not permitted under applicable law; or</ListItem>
          <ListItem>exclude any of our or your liabilities that may not be excluded under applicable law.</ListItem>
          <ListItem>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</ListItem>
        </UnorderedList>
        <Flex flexDir={'column'} mt={'20px'}>
          <Text> As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</Text>
        </Flex>
      </Flex>
      <FooterNavBar />
    </Flex >
  );
};

export default TermsPage;
