import { Box, Flex, Link } from "@chakra-ui/react";
import { cleanURL, ProfileIcon } from "views/common/common";

export function CompanyProfiles(props: {
  profiles: string[],
  website: string
}) {
  const { profiles, website } = props;
  function profileList(profiles: string[]) {
    let p: any[] = []
    //this is so that we only keep the last linkedin entry which uses linkedin id instead of profile name which can change
    for (let i = 0; i < profiles.length; i++) {
      if (i < (profiles.length - 1) && profiles[i].includes('linkedin') && profiles[i + 1].includes('linkedin')) {
        continue;
      }
      p.push(
        <Link href={cleanURL(profiles[i])} key={profiles[i]} isExternal>
          <ProfileIcon profile={profiles[i]} />
        </Link>
      )
    }
    return (
      <>{p}</>
    )
  }

  return (
    <Box>
      <Flex gap={4}>
        {profileList(profiles)}
        {website && <Link href={cleanURL(website)} isExternal>
          <ProfileIcon profile={website} />
        </Link>}
      </Flex>
    </Box>
  )
}


