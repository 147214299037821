import React, { useState } from 'react';
import { Flex, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

const CookiesPage = () => {

  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} pr={'100px'}>
      <NavBar />
      <Flex flexDir={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>Cookie Policy</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>Inwise AI Cookie Website Policy</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>Effective Date: January 10, 2024.</Text>
        </Flex>
      </Flex>
      <Flex flexDir={'column'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'} pb={'100px'}>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text mb={'5px'}>The Inwise AI website may use "cookies" to help you personalize your online experience. Cookies are small data files stored on your hard drive by a website. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you and can only be read by a web server in the domain that issued the cookie to you. We may use both session cookies (which expire when you close your web browser) and persistent cookies (which stay on your computer until you delete them) to provide you with a more personal and interactive experience on our Site.</Text>
          <Text mb={'5px'}>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Inwise AI pages or register with Inwise AI site or services, a cookie helps Inwise AI to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Inwise AI website, the information you previously provided can be retrieved, so you can easily use the Inwise AI features that you customized.</Text>
          <Text mb={'5px'}>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Inwise AI services or websites you visit.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Types of Cookies We Use</Text>
          <Text mb={'5px'}>We may use the following types of cookies:</Text>
          <UnorderedList pl={'20px'}>
            <ListItem>
              <Text mb={'10px'}>Strictly necessary cookies. Our website requires the use of these cookies to properly operate or provide necessary functions relating to the services you request. They include cookies that do the following: • enabling you to securely log into our website</Text></ListItem>
            <Text>These are persistent cookies that generally last 72 hours. Cookies will help create a better user experience</Text>
            <ListItem>Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our website when they are using it. This helps us improve how our website works by, for example, ensuring that users can easily find what they need on our website. These cookies generate aggregate statistics that are not associated with an individualized profile, and are session cookies.</ListItem>
            <ListItem>Functionality cookies. These cookies enable helpful but non-essential website functions that improve your website experience. By recognizing you when you return to our website, they may, for example, allow us to personalize our content for you, greet you by name, or remember your preferences (for example, your choice of language or region). These cookies may enable visitor identification over time, but not across different websites. These are persistent cookies that generally last 72 hours.</ListItem>
            <ListItem>Advertising, tracking or targeting cookies. These cookies enable different advertising related functions. They may allow us to record information about your visit to our website, such as pages visited, links followed, and videos viewed so we can make our website and the advertising displayed on it more relevant to your interests. These cookies may enable visitor identification over time, but not across different websites. These are persistent cookies that generally last 72 hours. </ListItem>
            <ListItem>Third-party cookies. Our website may contain helpful but non-essential features or plug-ins enabling third party services that use cookies, such as social network connectors, maps, advertising networks, or web traffic analysis services. These cookies may enable visitor identification across websites and over time. These third-party cookies are likely to be analytical cookies, performance cookies or targeting cookies. We do not control the third party's use of those cookies, their duration, or their ability to share information with other third parties. Please review each party's cookie disclosure before consenting to this use category.</ListItem>
          </UnorderedList>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Reviewing Our Privacy Policy</Text>
          <Text>Our Privacy Policy describes our practices for any personal data that our first-party cookies collect, store, or use, including our legal basis for processing personal data. We rely on the following legal basis to process personal data collected through our first-party cookies: Inwise AI may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on Inwise AI or the site; (b) protect and defend the rights or property of Inwise AI; and/or (c) act under exigent circumstances to protect the personal safety of users of Inwise AI, or the public.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Your Cookie Choices</Text>
          <Text>You may withdraw your consent for our use of any cookies that are not strictly necessary. You may do so at any time by contacting us.

            Most web browsers allow you to directly block all cookies, or just third-party cookies, through your browser settings. Using your browser settings to block all cookies, including strictly necessary ones, may interfere with proper site operation.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Changes to Our Cookie Uses</Text>
          <Text>We reserve the right to change this cookie policy as we may deem necessary or as may be required by law. We will post any changes to the way we use cookies on this page and/or indicate on our website that we updated this cookie policy. Your continued use of this website after we make a change is deemed to be an acceptance of those changes.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Contact Information</Text>
          <Text mb={'5px'}>Inwise AI welcomes your questions or comments regarding this policy. If you believe that Inwise AI has not adhered to this Statement, please contact Inwise AI:</Text>
          <UnorderedList pl={'20px'}>
            <ListItem>By email: admin@inwise.ai</ListItem>
            <ListItem>By visiting this page on our website: http://inwise.ai/contact</ListItem>
          </UnorderedList>
        </Flex>

      </Flex >
      <FooterNavBar />
    </Flex >
  );
};

export default CookiesPage;
