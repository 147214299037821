import { Text, useColorModeValue, ListItem, UnorderedList, Flex, Icon, Box } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { HowToWinIcon, BenefitsOverCompetitionIcon, RiskIcon } from 'components/icons/Icons';
import { TalkingPoints } from 'views/deals/Deal';
import IconHeaderList from './IconHeaderList';
import InsightSectionWithFeedback from '../../../../components/feedback/InsightSectionWithFeedback'

function TalkingPointContent(props: {
  content: string[],
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { content } = props;
  return (
    <UnorderedList>
      {content.map((bulletPoint, index) => (
        <ListItem key={index} mx="20px" >
          <Text ms='auto' color={textColor} me='6px'>
            {bulletPoint}
          </Text>
        </ListItem>
      ))}
    </UnorderedList>
  );
}

export default function TalkingPointsCard(props: {
  talkingPoints: TalkingPoints;
  [x: string]: any
}) {
  const { talkingPoints, ...rest } = props;
  const textColor = useColorModeValue('navy.700', 'white');
  if (!talkingPoints) return null;
  return (
    <Card mb='20px' p='20px' {...rest}>
      <Text color={textColor} fontSize='xl' fontWeight='700' mb='20px'>
        Talking Points
      </Text>
      <InsightSectionWithFeedback
        insights={talkingPoints.howToWin}
        sectionHeader={'How to Win:'}
        icon={HowToWinIcon} mb='15px'
      />
      <InsightSectionWithFeedback
        insights={talkingPoints.benefitsOverCompetition}
        sectionHeader={'Benefits over competition:'}
        icon={BenefitsOverCompetitionIcon} mb='15px'
      />
      <InsightSectionWithFeedback
        insights={talkingPoints.risk}
        sectionHeader={'Risk:'}
        icon={RiskIcon}
      />
    </Card>
  );
}
