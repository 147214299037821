import { Button, Flex, HStack, Image, Input, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';


export default function FooterNavBar() {
  const textColor = useColorModeValue('gray.400', 'white');
  return (
    <Flex
      zIndex='3'
      px={{ base: '30px', md: '50px' }}
      pb='30px' alignItems={'center'} justifyContent={'space-between'}>
      <Flex
        px='16px'
        alignItems='center'
        zIndex='3'>
        <Flex w='100%' justifyContent={{ sm: 'start', lg: 'space-between' }}>
          <HStack display={{ sm: 'none', lg: 'flex' }} spacing='40px'>
            <Stack
              direction='row'
              spacing='4px'
              alignItems='center'
              color='#fff'
              fontWeight='bold'
              cursor='pointer'
              position='relative'>
              <RouterLink to="/terms-of-use">
                <Text fontSize='sm' color={textColor}>
                  Terms of Use
                </Text>
              </RouterLink>
            </Stack>
            <Stack
              direction='row'
              spacing='4px'
              alignItems='center'
              color={'#fff'}
              fontWeight='bold'
              cursor='pointer'
              position='relative'>
              <RouterLink to="/privacy-policy">
                <Text fontSize='sm' color={textColor}>
                  Privacy Policy
                </Text>
              </RouterLink>
            </Stack>
            <Stack
              direction='row'
              spacing='4px'
              alignItems='center'
              color={'#fff'}
              fontWeight='bold'
              cursor='pointer'
              position='relative'>
              <RouterLink to="/cookies">
                <Text fontSize='sm' color={textColor}>
                  Cookies
                </Text>
              </RouterLink>
            </Stack>
            <Stack
              direction='row'
              spacing='4px'
              alignItems='center'
              color={'#fff'}
              fontWeight='bold'
              cursor='pointer'
              position='relative'>
              <RouterLink to="/zoom-docs">
                <Text fontSize='sm' color={textColor}>
                  Zoom Documentation
                </Text>
              </RouterLink>
            </Stack>
          </HStack>
        </Flex>
      </Flex>
      <Text
        w={'400px'}
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'end'
        }}
        mb={{ base: '20px', xl: '0px' }}>
        {' '}
        &copy; {new Date().getFullYear()}

        <Text as='span' fontWeight='500' ms='4px'>
          WiseAI. All Rights Reserved.
        </Text>
      </Text>
    </Flex>)
}
