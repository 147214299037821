import { Flex, Icon, useColorModeValue, Text, Button, Popover, PopoverBody, PopoverContent, PopoverHeader, PopoverTrigger, Input, InputRightElement, InputGroup, Box, HStack, useCheckbox, useCheckboxGroup, UseCheckboxProps } from '@chakra-ui/react';
import { SendIcon } from 'components/icons/Icons';
import { useEffect, useRef, useState } from 'react';

const giveInsightFeedbackURL = "https://inwise-node-functions2.azurewebsites.net/api/insight-feedback?code=DXTF-i51c1ShayaTfMghLtQNeuTpdARguw1DJcGc8ifvAzFulfv-CA%3D%3D"

export function FeedbackPopup({ insightId, isOpen, onClose, onToggle, onDismissed }: { insightId: string, isOpen: boolean, onClose: any, onToggle: any, onDismissed: any }) {
  const [message, setMessage] = useState('');
  const [showConfirmButton, setShowConfirmButton] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  const [showMessageInput, setShowMessageInput] = useState(true);
  const [optionsSentMessage, setOptionsSentMessage] = useState('');
  const [feedbackSentMessage, setFeedbackSentMessage] = useState('');
  const [hasOpened, setHasOpened] = useState(false);

  function handleMessageInputChange(value: any) {
    setMessage(value);
  }

  const handleSendMessage = async () => {
    setShowMessageInput(false);
    setFeedbackSentMessage('Message sent!');
    popoverContentRef.current.focus();

    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ insightId: insightId, message: message })
      });
      if (response.ok) {
        setShowMessageInput(false);
        setFeedbackSentMessage('Message sent! Thank you for your feedback.');
      }
    }
    catch (error) {
      console.error('Failed to send message', error)
      setFeedbackSentMessage('Failed to send message');
    }
  }

  const handleSelectionsConfirmed = async (selection: string[]) => {
    setShowOptions(false);
    setOptionsSentMessage('Feedback registered. Thank you!');
    popoverContentRef.current.focus();
    try {
      const response = await fetch(giveInsightFeedbackURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ insightId: insightId, reasons: selection })
      });
      if (response.ok) {
        setShowOptions(false);
        setOptionsSentMessage('Feedback registered. Thank you!');
      }
    }
    catch (error) {
      console.error('Failed to register feedback', error)
      setOptionsSentMessage('Failed to register feedback');
    }
  }

  useEffect(() => {
    //onToggle is called before the popup is actually opened
    if (hasOpened) {
      onDismissed()
      return;
    }
    //on open
    if (isOpen) {
      setHasOpened(true);
    }
  }, [onToggle]);

  const textColorLight = useColorModeValue('#c7cee2', 'white');
  const textColor = useColorModeValue('navy.700', 'white');

  const popoverContentRef = useRef(null);
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement='right' >
      <PopoverTrigger>
        <Flex></Flex>
      </PopoverTrigger>
      <PopoverContent width={'300px'} ref={popoverContentRef} _focus={{ borderColor: "none" }}>
        <PopoverHeader borderColor={textColorLight} borderBottomWidth={'1px'} pt={'4px'} pb={'3px'} fontWeight='700' color={textColor}>
          <Text fontSize={'sm'}>
            Ok! We will no longer show you this insight.
          </Text>
        </PopoverHeader>
        <PopoverBody >
          {showOptions && <Flex flexDir='column'>
            <Text fontSize={'xs'} >
              Help us improve by selecting all that apply:
            </Text>
          </Flex>}
          {showOptions && <OptionsSelectGroup isOpen={isOpen} onSelectionConfirmed={handleSelectionsConfirmed} />}
          {optionsSentMessage.length > 0 && <Text fontSize={'sm'} color={'#278197'} my={'16px'}>{optionsSentMessage}</Text>}
          {showMessageInput && <Flex w={'100%'} mt={'8px'}>
            <InputGroup h='30px'>
              <Input
                placeholder='Optionally, send us additional feedback'
                variant='outline'
                borderColor={'blackAlpha.300'}
                h='30px'
                maxH='30px'
                size={'xs'}
                borderRadius={'8px'}
                onChange={(e) => handleMessageInputChange(e.target.value)}
              />
              <InputRightElement h='30px' w={'30px'}>
                <Button size='xs' onClick={handleSendMessage} bg='none' _hover={{ color: 'none' }} p={'0px'} borderRadius={'0px'} isDisabled={message.length <= 0}>
                  <Icon as={SendIcon} w="20px" h="20px" color={'#0F738C'} _hover={{ color: '#278197' }} />
                </Button>
              </InputRightElement>
            </InputGroup>
          </Flex>}
          {feedbackSentMessage.length > 0 && <Text fontSize={'sm'} color={'#278197'} mb={'16px'} mt={'8px'}>{feedbackSentMessage}</Text>}
        </PopoverBody>
      </PopoverContent>
    </Popover >
  );
}

function CheckboxCard(props: { checkboxProps: UseCheckboxProps, [x: string]: any }) {
  const { checkboxProps, ...rest } = props
  const { getInputProps, getCheckboxProps } = useCheckbox({ ...checkboxProps });

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label" w={'45%'} {...rest}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor="pointer"
        borderWidth="1px"
        _checked={{
          bg: '#278197',
          color: "white",
        }}
        borderRadius={'6px'} fontWeight={'semibold'} borderColor={'blackAlpha.300'} fontSize={'xs'} textAlign={'center'} color='#278197' py={'2px'}
      >
        {checkboxProps.value}
      </Box>
    </Box>
  );
}

function OptionsSelectGroup(props:
  {
    isOpen: boolean,
    onSelectionConfirmed: (selection: string[]) => Promise<void>,
    [x: string]: any
  }) {
  const { isOpen, onSelectionConfirmed, ...rest } = props;
  const [checked, setChecked] = useState([]);
  const [showConfirmButton, setShowConfirmButton] = useState(false);

  const { getCheckboxProps, setValue } = useCheckboxGroup({
    onChange: (values) => {
      setChecked(values);
      setShowConfirmButton(values.length > 0);
    }
  });

  return (
    <Box>
      <HStack paddingY={2}>
        <Flex flexDir={'column'} w={'100%'} >
          <Flex pt={'4px'} w={'100%'} justifyContent={'space-between'}>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Inaccurate' })}>Inaccurate</CheckboxCard>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Irrelevant' })}>Irrelevant</CheckboxCard>
          </Flex>
          <Flex pt={'4px'} w={'100%'} justifyContent={'space-between'}>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Not helpful' })}>Not helpful</CheckboxCard>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Not timely' })}>Not timely</CheckboxCard>
          </Flex>
          <Flex pt={'4px'} w={'100%'} justifyContent={'space-between'}>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Too rudimentary' })}>Too rudimentary</CheckboxCard>
            <CheckboxCard checkboxProps={getCheckboxProps({ value: 'Too vague' })}>Too vague</CheckboxCard>
          </Flex>
        </Flex>
      </HStack>
      {showConfirmButton && <Flex w={'100%'} justifyContent={'flex-end'}>
        <Button colorScheme='brand' variant={'solid'} borderRadius={'8px'} fontWeight={'light'} size='xs' pl={'8px'} _hover={{ bg: '#278197' }} onClick={() => onSelectionConfirmed(checked)}>Ok!</Button>
      </Flex>}
    </Box>
  )
}

