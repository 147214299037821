import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';

import DealsAll from 'views/deals/all';
import ProspectsAll from 'views/prospects/all';
import Products from 'views/products';
import Settings from 'views/settings';
import { MyDealsNavIcon, MyProspectsNavIcon, ProductsNavIcon, SettingsIcon } from 'components/icons/Icons';
import DealsDashboard from 'views/deals/dashboard';
import AccountsAll from 'views/accounts/all';

const routes = [
  {
    name: 'Dashboard',
    path: 'deals/dashboard',
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: DealsDashboard
  },
  {
    name: 'My Deals',
    path: 'deals/all',
    icon: <Icon as={MyDealsNavIcon} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: DealsAll
  },
  {
    name: 'My Products',
    path: 'products',
    icon: <Icon as={ProductsNavIcon} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: Products
  },
  { /*TODO(KRIS) : get icon for this*/
    name: 'Accounts',
    path: 'accounts/all',
    icon: <Icon as={MyProspectsNavIcon} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: AccountsAll
  },
  {
    name: 'My Prospects',
    path: 'prospects/all',
    icon: <Icon as={MyProspectsNavIcon} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: ProspectsAll
  },
  {
    name: 'Settings',
    path: 'settings',
    icon: <Icon as={SettingsIcon} width='20px' height='20px' color='inherit' />,
    collapse: false,
    layout: '/',
    component: Settings
  },
];

export default routes;
