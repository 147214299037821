import {
  Box,
  Flex,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';

import React, { useState } from "react";
import AddToDeal from 'views/deals/create/components/AddToDeal';
import FindProspect from 'views/common/FindProspect';
import SelectProspect from 'views/common/SelectProspect';
import { ProspectSearchResult } from 'views/prospects/Prospect';
import { TabButton } from './common';

const searchPeopleDataURL = "https://inwise-node-functions2.azurewebsites.net/api/pdl-enrichment-search?code=PQdgvBwUMH-j9Fq4B2zVljPVNaMMCdz4pqKYztHEmytSAzFunx1HDA%3D%3D";
export interface ProspectFormData {
  firstName: string;
  lastName: string;
  company: string;
  location: string;
}

export default function SearchAndCreate(props: {
  variant: 'createDeal' | 'addProspect'
}) {
  const { variant } = props;

  const findProspectTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const selectProspectTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const addToDealTab = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const [prospectSearchResults, setProspectSearchResults] = useState<ProspectSearchResult[]>()
  const [prospect, setProspect] = useState<ProspectSearchResult>();

  interface CompanyFormData {
    companyName: string;
  }

  const handleFindProspectSubmit = async (formData: ProspectFormData) => {
    setProspectSearchResults(null);
    setTabIndex(tabIndex + 1);
    try {
      const response = await fetch(searchPeopleDataURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ command: 'people', data: formData }),
      });

      if (response.ok) {
        const responseData = await response.json();
        setProspectSearchResults(responseData);
      } else {
        console.error('Error sending data:', response.status, response.statusText);
        setProspectSearchResults([]);
      }
    } catch (error) {
      console.error('Error sending data:', error);
      setProspectSearchResults([]);
    }
  };


  const handleSelectProspect = async (selectedProspect: ProspectSearchResult) => {
    if (!selectedProspect) return
    setProspect(selectedProspect)
    setTabIndex(tabIndex + 1);
  }

  const handleReturnClicked = async () => {
    setTabIndex(tabIndex < 1 ? 0 : (tabIndex - 1))
  }

  const [tabIndex, setTabIndex] = useState(0)
  return (
    <Flex direction='column' align='center' pt={'75px'} position='relative'>
      <Box
        h='45vh'
        bg='linear-gradient(135deg, #04647C 0%, #00A6D1 100%)'
        position='absolute'
        w='100%'
        borderRadius='30px'
      />
      <Tabs
        variant='unstyled'
        mt={'60px'}
        zIndex='0'
        display='flex'
        flexDirection='column' index={tabIndex} onChange={(index: number) => setTabIndex(index)} w={{ sm: '100%', md: '90%', lg: '80%', xl: '65%' }}>
        <TabList display='flex' alignItems='stretch' alignSelf='center' justifySelf='center' w={'100%'}>
          <TabButton ref={findProspectTab} label='Find Prospect' />
          <TabButton ref={selectProspectTab} label='Select Prospect' />
          <TabButton ref={addToDealTab} label={variant == 'createDeal' ? 'Create Deal' : 'Add to Deal'} />
        </TabList>
        <TabPanels mt='24px' mx='auto'>
          <TabPanel p='0px'>
            {<FindProspect
              onSubmit={handleFindProspectSubmit}
              onReturn={handleReturnClicked} />}
          </TabPanel>
          <TabPanel p='0px'>
            <SelectProspect
              data={prospectSearchResults}
              onSelectProspect={handleSelectProspect}
              onReturn={handleReturnClicked} />
          </TabPanel>
          <TabPanel p='0px'>
            {prospect && <AddToDeal
              prospect={prospect}
              variant={variant}
              onReturn={handleReturnClicked} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}
