import { useState } from 'react';
import { Tabs, TabList, TabPanels, Tab, TabPanel, Box, Flex, Text } from '@chakra-ui/react';
import DealCard from 'views/deals/dashboard/components/DealCard';
import { DealSummary, DealPhase, Deal } from 'views/deals/Deal';

export default function DealsTabContainer(props: { dealSummaries: Deal[], dealsSubscribed?: string[] }) {
  const textColor = '#3D4B9B';
  const textColor2 = '#A3AED0';
  const { dealSummaries, dealsSubscribed } = props;
  const dealsByPhase: { [key: string]: Deal[] } = {};

  dealSummaries?.forEach((dealSummary) => {
    if (!dealsByPhase[dealSummary.dealCardInfo?.stage?.content]) {
      dealsByPhase[dealSummary.dealCardInfo?.stage?.content] = [];
    }
    dealsByPhase[dealSummary.dealCardInfo?.stage?.content].push(dealSummary);
  });

  const [activeTab, setActiveTab] = useState<number>(0);
  const sortedDealPhases = Object.keys(dealsByPhase).sort((a, b) => {
    const phaseA = Object.values(DealPhase).indexOf(a as DealPhase);
    const phaseB = Object.values(DealPhase).indexOf(b as DealPhase);
    return phaseA - phaseB;
  });
  return (
    <Tabs w='100%' p={2} borderRadius='30px' variant="unstyled" index={activeTab} size='md' onChange={(index) => setActiveTab(index)}>
      <TabList pl={'30px'}>
        {sortedDealPhases.map((dealPhase, index) => (
          <Tab key={index}
            fontWeight={'medium'}
            color={index === activeTab ? textColor : textColor2}
            _selected={{ color: textColor, bg: 'none' }}
            px='0px'
            mr={4}
            flexDirection='column'
            bg='unset'
            minW='max-content'
            _focus={{ border: 'none' }}
          >
            <Flex align='center'>
              <Text color={textColor} fontSize='lg' fontWeight='500' me='12px'>
                {dealPhase}
              </Text>
              <Text color='secondaryGray.600' fontSize='md' fontWeight='400'>
                ({dealsByPhase[dealPhase].length})
              </Text>
            </Flex>
            <Box
              height='4px'
              w='100%'
              transition='0.1s linear'
              bg={index === activeTab ? 'brand.500' : 'transparent'}
              mt='15px'
              borderRadius='30px'
            />
          </Tab>
        ))}
      </TabList>
      <TabPanels pt={4}>
        {sortedDealPhases.map((dealPhase, index) => (
          <TabPanel key={index} p={0}>
            {dealsByPhase[dealPhase].map((deal) => (
              <DealCard w='100%' mb={'16px'} deal={deal} key={deal._id} variant={'dashboard'} dealsSubscribed={dealsSubscribed} />
            ))}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};
