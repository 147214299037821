import { Flex, Text, LinkBox, LinkOverlay, Stack, Button, useColorModeValue, Icon, Tooltip } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HSeparator } from 'components/separator/Separator';
import Card from 'components/card/Card';
import { DealPhase, Deal, } from 'views/deals/Deal';
import { DealStageItem } from 'views/deals/overview/components/DealStageCard';
import { useState } from 'react';
import { EditIcon } from 'components/icons/Icons';
import DealCardEdit from './DealCardEdit';
import { timestampToDate, dateToYYYYMMDD } from '../Common';

const subcribeDealUrl = "https://inwise-node-functions2.azurewebsites.net/api/deal-subscribe-user?code=r7yvrNvhX4Xl5UOR2Pja-FvQRZHTRSLXtV0UCOrlKofrAzFuk9L0Hg%3D%3D";

export default function DealCard(props: {
  deal: Deal,
  variant: 'dashboard' | 'overview',
  dealsSubscribed?: string[],
  [x: string]: any,
}) {
  const { deal, variant, dealsSubscribed, ...rest } = props;
  if (!deal) return null;
  const info = deal.dealCardInfo;
  const isClosed = info?.stage?.content == DealPhase.Closed;
  const timeToCloseLabel = isClosed ? 'Time to Close' : 'Est. time to close';
  const direction = variant === 'overview' ? 'column' : 'row';
  const date = info?.daysSinceLastInteraction?.content ? timestampToDate(info?.daysSinceLastInteraction?.content) : null;
  const lastInteractionDate = (date && !isNaN(date.valueOf())) ? date : null;
  const lastInteractionString = lastInteractionDate ? dateToYYYYMMDD(lastInteractionDate) : 'Not analyzed yet';

  const [subscribeTooltipText, setSubscribeTooltipText] = useState(dealsSubscribed?.includes(deal._id) ? "Remove from My Deals" : "Add to My Deals");
  const [editMode, setEditMode] = useState(false);
  const [editedValues, setEditedValues] = useState({
    stage: info?.stage?.content ?? 'Not analyzed yet',
    likelihoodToClose: info?.likelihoodToClose?.content ?? 'Not calculated yet',
    timeToClose: (info?.estimatedClose?.content) ? ` ${info?.estimatedClose?.content}` : 'Not calculated yet',
    sentiment: info?.sentiment?.content ?? 'Not calculated yet',
    size: info?.size?.content ?? 'Not calculated yet',
    daysSinceLastInteraction: lastInteractionString
  });

  const [isHovered, setIsHovered] = useState(false);
  const iconColorLight = useColorModeValue("#0F738CBF", "white");
  const iconColorDark = useColorModeValue("#0F738C", "white");

  function handleSubscribe(dealId: string) {
    const subscribe = !dealsSubscribed.includes(deal._id);
    fetch(subcribeDealUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ accessToken: localStorage.getItem("accessToken"), dealId, subscribe }),
    })
      .then(() => {
        setSubscribeTooltipText(subscribeTooltipText === "Add to My Deals" ? "Remove from My Deals" : "Add to My Deals");
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }

  const handleEditCancelled = () => {
    setEditMode(false);
    setIsHovered(false);
  }

  const handleEditSaved = (updatedValues: any) => {
    setEditedValues({
      stage: updatedValues.stage ?? 'Not analyzed yet',
      likelihoodToClose: updatedValues.likelihoodToClose ?? 'Not calculated yet',
      timeToClose: updatedValues.timeToClose ? ` ${updatedValues.timeToClose} days` : 'Not calculated yet',
      sentiment: updatedValues.sentiment ?? 'Not calculated yet',
      size: updatedValues.size ?? 'Not calculated yet',
      daysSinceLastInteraction: updatedValues.daysSinceLastInteraction,
    });
    setEditMode(false);
    setIsHovered(false);
  }

  return (
    <LinkBox as='article'>
      <Card
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        height={'100%'}
        minW={variant == 'dashboard' ? '550px' : '100%'}
        p={'20px'}
        mb={variant == 'dashboard' ? '0px' : '20px'}
        maxW={'600px'}
        {...rest}>
        <Flex display={'flex'} flexDirection={'column'} width={'100%'}>
          {variant == 'dashboard' && <>
            <LinkOverlay as={Link} to={{
              pathname: `/deals/overview/${deal._id}`,
              state: {
                dealSummary: { deal },
                routeName: `Deal with ${deal.name}`
              }
            }}>
            </LinkOverlay>
            <Flex display={'flex'} justifyContent={'space-between'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} zIndex={2}>
              <Text fontSize={'2xl'} fontWeight={'extrabold'} justifyContent='flex-start' textTransform={'capitalize'}>{deal.name}</Text>
              <Tooltip label={subscribeTooltipText}>
                <Button fontSize='xl' bg={'transparent'} color={isHovered ? 'brand.500' : 'blackAlpha.400'} fontWeight='700' size={'sm'} onClick={() => handleSubscribe(deal._id)}>{subscribeTooltipText === 'Add to My Deals' ? '+' : '-'}</Button>
              </Tooltip>
            </Flex>
            <HSeparator mb='16px' />
          </>}
        </Flex>
        <Flex w='100%' h={'100%'} alignItems={'flex-start'} flexDir={direction}>
          <Flex h={'100%'} w={variant == 'dashboard' ? '35%' : '100%'} minW='275px' maxW='500px' mb={variant == 'dashboard' ? '0px' : '30px'}>
            <Stack h='100%' gap={2} w='100%' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
              {editMode ? (
                <DealCardEdit deal={deal} onEditCancelled={handleEditCancelled} onEditSaved={handleEditSaved} />
              ) : (
                <>
                  <DealStageItem name={'Deal stage'} value={editedValues.stage} />
                  {!isClosed && <DealStageItem name={'Likelihood to close'} value={editedValues.likelihoodToClose} />}
                  <DealStageItem name={timeToCloseLabel} value={editedValues.timeToClose} />
                  <DealStageItem name={'Sentiment'} value={editedValues.sentiment} />
                  <DealStageItem name={'Size'} value={editedValues.size} />
                  <DealStageItem name={'Last interaction'} value={editedValues.daysSinceLastInteraction} />
                </>
              )}
            </Stack>
          </Flex>
          {variant == 'overview' && !editMode &&
            <Flex position="absolute" justifyContent="flex-end" w={'90%'}>
              {isHovered &&
                <>
                  <Flex
                    as="button"
                    onClick={() => setEditMode(true)}
                    color={iconColorLight}
                    ml='4px'
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)} p={'5px'}
                  >
                    <Icon as={EditIcon} w="40px" h="40px" bg="#ffffffBF" borderRadius={'50%'} p={'10px'} boxShadow={'0 4px 8px 0 rgba(0,0,0,0.1)'} _hover={{ background: "#ffffff", color: iconColorDark }} />
                  </Flex>
                </>
              }
            </Flex>
          }
          {deal.dealSummaryContent?.executiveSummary?.length > 0 && !editMode && <Flex
            pl={variant == 'dashboard' ? '30px' : '0px'}
            maxH={'250px'}
            flexGrow={1}
            overflow={'hidden'}
            overflowY="scroll"
            css={{
              '&::-webkit-scrollbar': { width: '8px', },
              '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.)', borderRadius: '4px', },
              '&:hover::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', },
              '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0)', borderRadius: '4px', },
              '&:hover::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', },
            }}
          >
            <Text fontWeight={'normal'}>
              {deal.dealSummaryContent?.executiveSummary[0].content}
            </Text>
          </Flex>}
        </Flex>
      </Card>
    </LinkBox>
  );
}
