import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

import Frame from './frame';
import theme from './theme/theme';
import './assets/css/App.css';
import LoginPage from './views/login/loginPage';
import IndexPage from './views/login';
import AboutPage from './views/login/aboutPage'
import ContactPage from 'views/login/contactPage';
import TermsPage from 'views/login/termsPage';
import PrivacyPage from 'views/login/privacyPage';
import CookiesPage from 'views/login/cookiesPage';
import DocsPage from 'views/login/docsPage';
import MicrosoftLoginRedirect from 'views/login/MicrosoftLoginRedirect';
import MicrosoftLoginCallback from 'views/login/MicrosoftLoginCallback';

const validateTokenUrl = "https://appwise-functions.azurewebsites.net/api/login-token-validate?code=36BJVxq2x1LlvEswxcgyq4Mlyiq4v1Gl3dA30SRATxXPAzFu3pDWbQ%3D%3D"
const msalInstance = new PublicClientApplication(msalConfig);

async function isLoggedUser() {
  if (!localStorage.getItem("accessToken")) {
    return false;
  }
  console.log('sending request to authenticate token');
  const body = localStorage.getItem("loggedWithGoogle")
    ? { accessToken: localStorage.getItem("accessToken"), lastFetch: localStorage.getItem("lastFetch") }
    : { accessToken: localStorage.getItem("accessToken") };
  return await fetch(validateTokenUrl, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
    .then(res => res.json())
    .then(validateResponse => validateResponse.valid)
    .catch((error: Error) => {
      console.log(error);
      return false;
    });
}

const AuthenticatedApp = () => (
  <Switch>
    <Route path="/" component={Frame} />
  </Switch>
);

const UnauthenticatedApp = () => (
  <Switch>
    <Route path="/login" component={LoginPage} />
    <Route path="/ms-login" component={MicrosoftLoginRedirect} />
    <Route path="/ms-callback" component={MicrosoftLoginCallback} />
    <Route path="/about" component={AboutPage} />
    <Route path="/contact" component={ContactPage} />
    <Route path="/terms-of-use" component={TermsPage} />
    <Route path="/privacy-policy" component={PrivacyPage} />
    <Route path="/cookies" component={CookiesPage} />
    <Route path="/zoom-docs" component={DocsPage} />
    <Route path="/" component={IndexPage} />
  </Switch>
);

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const checkAuthentication = async () => {
      const result = await isLoggedUser();
      setIsAuthenticated(result);
    };
    checkAuthentication();
  }, []);

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </BrowserRouter>
  );
};

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <GoogleOAuthProvider clientId="488689976032-7o0um6cjmonn0pcj6cdtlfseg383tsie.apps.googleusercontent.com">
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </React.StrictMode>
    </GoogleOAuthProvider>
  </ChakraProvider>,
  document.getElementById('root')
);
