import { useState } from 'react';
import {
  Flex,
  Text,
  useColorModeValue,
  Table, Tbody, Thead, Tr,
  RadioGroup,
  CloseButton,
  Spinner,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { CompanySearchResult } from 'views/prospects/Prospect';
import { CompanyProfiles } from './Common';
import { TableHeader, TableRowWithRadio, BackButton, NextButton } from 'views/common/common';

export default function SelectCompany(props: {
  data: CompanySearchResult[],
  onSelectCompany: (selectedCompany: CompanySearchResult) => void;
  onReturn: () => void;
  onCancel?: () => void;
  [x: string]: any;
}) {
  const { data, onSelectCompany, onReturn, onCancel, ...rest } = props;
  const [selectedCompany, setSelectedCompany] = useState<CompanySearchResult | null>(null);

  const handleGenerateSignals = async () => {
    try {
      if (selectedCompany) { onSelectCompany(selectedCompany); }
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  function SetCompanyData(id: string) {
    setSelectedId(id)
    let c = data.find(company => company.id == id)
    setSelectedCompany(c)
  }

  //TODO(KRIS) : find out what the next buttons here and in Select prospect are supposed to say
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const textColor = useColorModeValue('navy.700', 'white');
  const headerValues: string[] = ['NAME', 'INDUSTRY', 'PROFILES', '']
  return (
    <Card p='30px' {...rest}>
      {data ? (
        data.length > 0 ? (
          <>
            <Flex w={'100%'} justify='space-between'>
              <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
                Select Company
              </Text>
              <CloseButton onClick={onCancel} />
            </Flex>
            <RadioGroup onChange={(value) => SetCompanyData(value)} value={selectedId}
              maxHeight='450px' overflowY={'auto'} css={{
                '&::-webkit-scrollbar': { width: '8px', },
                '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: '4px', },
                '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '4px', },
              }}>
              <Table variant='simple' color='gray.500'>
                <Thead position={'sticky'} top='0' backdropFilter={'blur(10px)'}>
                  <Tr>
                    {headerValues.map((header, index) => (
                      <TableHeader key={index} header={header} index={index} />
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((company: CompanySearchResult) => (
                    <TableRowWithRadio rowContents={[company.display_name, company.industry, <CompanyProfiles profiles={company.profiles} website={company.website} />]} radioValue={company.id} key={company.id} />
                  ))}
                </Tbody>
              </Table>
            </RadioGroup>
          </>
        ) : (
          <Text color={textColor} fontSize='lg' fontWeight='500' mb={'3px'} pb={'100px'}>No companies found</Text>
        )
      ) : (
        <Flex w={'100%'} justifyContent='start' alignItems={'center'} flexDir={'column'} py={'30px'}>
          <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} />
          <Text color={textColor} fontWeight={'700'}>Searching for companies...</Text>
        </Flex>
      )}
      <Flex justify='space-between' mt='24px'>
        <BackButton onClick={onReturn} />
        {data ? <NextButton
          disabled={!selectedId}
          onClick={() => { handleGenerateSignals() }}
          buttonText='Set company'
        /> : null}
      </Flex>
    </Card>
  )
}
