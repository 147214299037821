import { useEffect, useState } from "react";
import {
  Flex,
  FormControl,
  FormErrorMessage,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import Card from "components/card/Card";
import InputField from "components/fields/InputField";
import { BackButton, NextButton } from "./common";

export default function FindProspect(props: {
  onSubmit: (formData: any) => Promise<void>;
  onReturn: () => Promise<void>
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { onSubmit, onReturn } = props;
  // Define state for input fields
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    location: '',
  });

  useEffect(() => {
    setFormData({ firstName: '', lastName: '', company: '', location: '' })
    console.log('resetting form data')
  }, []);

  const handleInputChange = (name: string, value: string) => {
    if (formData.company !== '' && formData.location !== '') {
      setInputError('');
    }
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    if (formData.company === '' && formData.location === '') {
      setInputError('Please enter either a company or location');
      return;
    }
    try {
      const response = await onSubmit(formData);
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };

  //TODO(KRIS) : make these stack when w is too small
  const [inputError, setInputError] = useState('');
  return (
    <Card p='30px'>
      <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
        {'Find a Prospect'}
      </Text>
      <Flex direction='column' w='100%'>
        <SimpleGrid columns={{ base: 2, md: 1 }} gap='20px'>
          <Stack direction='row' gap='20px'>
            <InputField
              mb='0px'
              id='name'
              placeholder='eg. Alethea'
              value={formData.firstName}
              label='First Name *'
              onChange={(value: any) => handleInputChange('firstName', value.target.value)
              }
            />
            <InputField
              mb='0px'
              id='lastName'
              placeholder='eg. Adekanbi'
              label='Last Name *'
              value={formData.lastName}
              onChange={(value: any) => handleInputChange('lastName', value.target.value)}
            />
          </Stack>
          <FormControl isInvalid={inputError !== ''}>
            <Stack direction='row' gap='20px' justifyContent={'stretch'} >
              <Flex justifyContent={'space-between'} w={'100%'}>
                <Flex w={'100%'} pr={'25px'}>
                  <InputField
                    mb='0px'
                    id='company'
                    placeholder='eg. Google'
                    label='Company'
                    value={formData.company}
                    onChange={(value: any) => handleInputChange('company', value.target.value)}
                  />
                </Flex>
                <Text color={textColor} fontWeight={'bold'}>OR</Text>
              </Flex>
              <InputField
                mb='0px'
                id='location'
                placeholder='eg. Seattle'
                label='Location'
                value={formData.location}
                onChange={(value: any) => handleInputChange('location', value.target.value)}
              />
            </Stack>
            {inputError && <FormErrorMessage pt={'5px'} pl={'20px'} w={'100%'}>{inputError}</FormErrorMessage>}
          </FormControl>
        </SimpleGrid>
        <Flex justify='space-between' mt='24px'>
          <BackButton onClick={onReturn} display={'none'} />
          <Flex />
          <NextButton
            onClick={handleSubmit}
            disabled={(formData.firstName.length < 1 || formData.lastName.length < 1) || (formData.company.length < 1 && formData.location.length < 1)}
          />
        </Flex>
      </Flex>
    </Card>
  );
}
