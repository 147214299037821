import { useState } from 'react';
import {
  Flex,
  Text,
  useColorModeValue,
  Table, Tbody, Thead, Tr,
  RadioGroup,
  Avatar,
  Spinner,
} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { ProspectSearchResult } from 'views/prospects/Prospect';
import { BackButton, NextButton, TableHeader, TableRowWithRadio } from 'views/common/common';


export default function SelectProspect(props: {
  data: ProspectSearchResult[],
  onSelectProspect: (selectedContact: ProspectSearchResult) => void,
  onReturn: () => Promise<void>
}) {
  const { data, onSelectProspect, onReturn } = props;
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const textColor = useColorModeValue('navy.700', 'white');
  const handleGenerateSignals = async () => {
    try {
      if (selectedId) {
        let c = data.find((prospect: { id: string; }) => prospect.id == selectedId)
        onSelectProspect(c);
      }
    } catch (error) {
      console.error('Error sending data:', error);
    }
  };
  console.log(data)
  const headerValues: string[] = ['', 'NAME', 'COMPANY', 'TITLE', 'LOCATION', '']
  return (
    <Card p='30px' overflow={'hidden'}>
      {data ? (
        data.length > 0 ? (
          <>
            <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
              Select Prospect
            </Text>
            <RadioGroup
              onChange={(value) => setSelectedId(value)}
              value={selectedId}
              maxHeight='450px'
              overflowY={'auto'}
              css={{
                '&::-webkit-scrollbar': { width: '8px' },
                '&::-webkit-scrollbar-track': { backgroundColor: 'rgba(0, 0, 0, 0.1)', borderRadius: '4px' },
                '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0, 0, 0, 0.3)', borderRadius: '4px' },
              }}
            >
              <Table variant='simple' color='gray.500'>
                <Thead position={'sticky'} top='0' backdropFilter={'blur(10px)'}>
                  <Tr>
                    {headerValues.map((header, index) => (
                      <TableHeader key={index} header={header} index={index} />
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {data.map((prospect: ProspectSearchResult) => (
                    <TableRowWithRadio
                      rowContents={[
                        <Flex><Avatar w="34px" h="34px" src={prospect.pictureLink} /></Flex>,
                        prospect.full_name,
                        prospect.job_company_name,
                        prospect.job_title,
                        prospect.location_name
                      ]}
                      radioValue={prospect.id}
                      key={prospect.id}
                      variant='removeLeftPadding'
                    />
                  ))}
                </Tbody>
              </Table>
            </RadioGroup>
          </>
        ) : (
          <Text color={textColor} fontSize='lg' fontWeight='500' h={'200px'}>No prospects found</Text>
        )
      ) : (
        <Flex w={'100%'} h={'200px'} justifyContent='start' alignItems={'center'} flexDir={'column'} pt={'30px'}>
          <Spinner color='brand.500' thickness='3px' emptyColor='gray.200' mb={'20px'} />
          <Text color={textColor} fontWeight={'700'}>Searching for prospects...</Text>
        </Flex>
      )}
      <Flex justify='space-between' mt='24px'>
        <BackButton onClick={onReturn} />
        {data && (
          <NextButton
            disabled={!selectedId}
            onClick={handleGenerateSignals}
            buttonText={'Generate Signals'}
          />
        )}
      </Flex>
    </Card>
  );
}
