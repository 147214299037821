import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Deal } from 'views/deals/Deal'

export function DealStageItem(props: {
  name: string,
  value: string,
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { name, value, } = props;
  return (
    <Flex justifyContent='center' alignItems='center' w='100%'>
      <Flex direction='column' align='start' me='auto'>
        <Text color={textColor}>
          {name}
        </Text>
      </Flex>
      <Text ms='auto' color={textColor} fontSize='sm' me='6px' fontWeight='700' textAlign={'end'}>
        {value}
      </Text>
    </Flex>
  );
}

