export function timestampToDate(timestamp: string) {
  const parsedTimestamp = parseInt(timestamp, 10);
  // Check if the timestamp is in milliseconds (length 13) or seconds (length 10)
  return timestamp.length === 13 ?
    new Date(parsedTimestamp) :
    new Date(parsedTimestamp / 1000);
}

export function dateToYYYYMMDD(date: Date) {
  return `${date.toLocaleString('default', { month: 'long' })} ${date.getDate()}, ${date.getFullYear()}`;
};
