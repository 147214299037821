export const productItems = [
  {
    header: 'Value Proposition',
    content:
      "Kipi.bi helps businesses unlock the potential of their data by providing a variety of data-driven business solutions, including strategy, analytics, and AI. They are a Snowflake Elite Services Partner and a leader in data and analytics.",
  },
  {
    header: 'Competitive Strategy',
    content:
      "Kipi.bi positions itself as a one-stop shop for all data needs, offering a comprehensive suite of services (strategy, analytics, AI) on top of being a Snowflake Elite Services Partner. This suggests they target larger businesses seeking a full-service solution leveraging Snowflake's cloud-based platform.",
  },
  {
    header: 'Customer Testimonials',
    content:
      "Kipi has over 100 customers and have achieved Snowflake Elite Services Partner status, which indicates a record of successful client implementations.  They also mention positive feedback from clients about their expertise and service delivery.",
  },
  {
    header: 'Case Studies',
    content:
      "Kipi.bi showcases successful projects in various industries on their website, including healthcare, finance, technology, and manufacturing. These case studies highlight how Kipi.bi helped clients improve efficiency, optimize costs, and gain data-driven insights  using the Snowflake cloud platform.",
  },
  {
    header: 'Pricing Strategy',
    content:
      "Kipi.bi offers custom pricing based on customers",
  },
];

