// main source code is from here:  github.com/chakra-ui/chakra-ui/issues/457
// rn submit doesnt do anything except display preview
// afaik clicking 'browse' opens file selection and selecting a file (pdf/doc/ppt + png for testing) should show some sort of preview or at least name and size of file
// anything is tbd for later
// TODO: format sizes for submitted files
// TODO: multiple file upload
// TODO: non-img prev, multiple file prev
// misc layout/styling things

import React, { ReactNode, useRef, useState } from 'react';
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, InputGroup, Text } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { UploadIcon } from 'components/icons/Icons';

export default function FileUploader(props: {
  accept?: string;
  multiple?: boolean;
  onFormSubmit: (data: { file_: FileList }) => void;
  children?: ReactNode;
  buttonText: string;
}) {
  const { accept, multiple, onFormSubmit, children, buttonText } = props;
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const onSubmit = () => {
    if (!selectedFiles || selectedFiles.length === 0) {
      console.log('No files selected');
    } else {
      onFormSubmit({ file_: selectedFiles });
      setSelectedFiles(null);
    }
  };

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const isSubmitDisabled = !selectedFiles || selectedFiles.length === 0;

  return (
    <Flex w='100%' flexDirection='column'>
      <Flex w={'100%'} alignItems={'flex-start'}>
        {selectedFiles && Array.from(selectedFiles).map((file, index) => (
          <Box key={index} ml={'24px'} pb={'24px'}>
            {file.type.startsWith('image/') && (
              <img src={URL.createObjectURL(file)} alt={`Preview ${index}`} width={'200px'} />
            )}
            <Text pt={'8px'}>File name: {file.name}</Text>
          </Box>
        ))}
      </Flex>
      <Flex w={'100%'} borderRadius={'13px'} justifyContent={'center'} bg={'#FAFCFE'} borderStyle={'dashed'} borderColor={'#E0E5F2'} boxShadow={"14px 17px 40px 4px rgba(112, 144, 176, 0.08)"} borderWidth={'2px'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.file_}>
            <FormLabel htmlFor='fileInput' h='175px' w={'100%'}>
              <Flex alignItems='center' flexDirection='column' cursor='pointer' w={'100%'}>
                <Icon as={UploadIcon} boxSize='80px' borderRadius='15px' padding='7px' color='#0F738C' />
                <Text fontSize='md' fontWeight='extrabold'>
                  Drop your files here, or{' '}
                  <label htmlFor="fileInput" style={{ color: '#0F738C', cursor: 'pointer' }}>browse</label>
                </Text>
                <Text fontSize='s' py='25px' color={'#8F9BBA'}>
                  {accept} FILES ALLOWED
                </Text>
              </Flex>
            </FormLabel>
            <InputGroup onClick={handleClick}>
              <input
                type={'file'}
                hidden
                accept={accept}
                multiple={multiple}
                id='fileInput'
                {...register('file_')}
                ref={(e) => {
                  if (e) {
                    inputRef.current = e;
                  }
                }}
                onChange={handleFileChange}
              />
              {children}
            </InputGroup>
            <FormErrorMessage>
              {errors.file_ && errors?.file_.message}
            </FormErrorMessage>
          </FormControl>
          <Flex>
            <Button type='submit' bg='#0F738C' color='white' w='100%' h='50px' mb='33px' disabled={isSubmitDisabled} _hover={{ bg: '#0F738C' }}>
              {buttonText}
            </Button>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
}
