import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Icon,
  Stack,
  LinkBox,
  LinkOverlay,
  Link,
  Tooltip
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link as RouterLink } from "react-router-dom";


import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import "assets/css/MiniCalendar.css";
import Card from "components/card/Card";
import { Meeting } from "views/deals/Deal";
import { getFormattedDateTime, MeetingDetailsCard } from "./MeetingDetailsCard";
import MeetingCalendar from "./MeetingCalendar";

export function MeetingSummary(props: { meetingData: Meeting, variant: 'withTitle' | 'withoutTitle', [x: string]: any }) {
  // TODO: unify "generatedSummary" or "meetingSummaryPoints"
  const textColor = useColorModeValue("navy.700", "white");
  const { meetingData, variant, ...rest } = props;
  const meetingSummaryPoints = meetingData.generatedSummary
  if (!meetingData) return null
  return (
    <Flex direction={{ base: "column" }} {...rest}>
      {variant == 'withTitle' && <Text color={textColor} fontSize={"lg"} fontWeight="700" mb="10px">
        Meeting summary for '{meetingData.name}' on {meetingData.date}
      </Text>}
      <Box>
        {meetingSummaryPoints.map((meetingSummaryPoint, index) => (
          <Box key={index}>
            <Text fontSize={"md"} ms="auto" me="6px">
              {meetingSummaryPoint}
            </Text>
          </Box>
        ))}
      </Box>
    </Flex>
  );
}

export default function MeetingCard(props: { meetings: Meeting[], variant: 'full' | 'mini', onSelectionChanged: (selectedMeeting: Meeting) => void, [x: string]: any }) {
  const { meetings, onSelectionChanged, variant, ...rest } = props;
  const nextMeeting = meetings?.find(m => m.isNext);
  const lastMeeting = meetings?.find(m => m.isLast);
  const initialIndex = nextMeeting ? meetings.indexOf(nextMeeting) :
    lastMeeting ? meetings.indexOf(lastMeeting) : 0;

  const [selectedDate, onSelectedDateChange] = useState(new Date());
  const [selectedMeetingIndex, setSelectedMeetingIndex] = useState(initialIndex);
  const [selectedMeetingData, setSelectedMeetingData] = useState<Meeting>(
    meetings[0]
  );

  useEffect(() => {
    setSelectedMeetingData(meetings[selectedMeetingIndex]);
    onSelectionChanged(meetings[selectedMeetingIndex]);
  }, [meetings, selectedMeetingIndex]);

  const handleClickPrevArrow = () => {
    if (selectedMeetingIndex !== 0) {
      setSelectedMeetingIndex(selectedMeetingIndex - 1);
    }
  };

  const handleClickNextArrow = () => {
    if (selectedMeetingIndex < meetings.length - 1) {
      setSelectedMeetingIndex(selectedMeetingIndex + 1);
    }
  };

  const handleChangeSelectedDate = async (newValue: Date) => {
    onSelectedDateChange(newValue);

    const selectedYear = newValue.getFullYear();
    const selectedMonth = newValue.getMonth();
    const selectedDay = newValue.getDate();

    const selectedMoment = moment(newValue);
    const selectedMeetingIndex = meetings.findIndex((meeting: Meeting) => {
      let meetingDate = moment(meeting.date + ' ' + meeting.startTime, 'YYYY-MM-DD HH:mm:ss Z'); // Ensure the correct format
      const meetingYear = meetingDate.year();
      const meetingMonth = meetingDate.month(); // Note: month is zero-indexed in moment
      const meetingDay = meetingDate.date();

      if (
        selectedYear === meetingYear &&
        selectedMonth === meetingMonth &&
        selectedDay === meetingDay
      ) {
        return true;
      }
      return meetingDate.isAfter(selectedMoment); // Find the next future meeting
    });

    if (selectedMeetingIndex !== -1) {
      setSelectedMeetingIndex(selectedMeetingIndex);
    }
  };

  return (
    <Card {...rest}>
      <Flex direction={{ base: "column" }}>
        <Flex justifyContent="space-between" align="flex-start" w="100%" flexDir={variant === 'full' ? 'row' : 'column'}>
          <Box
            alignItems="flex-start"
            mr="20px"
            boxShadow={"none"}
            minW={variant === 'full' ? '275px' : 'auto'}
          >
            <MeetingCalendar meetings={meetings} selectedDate={selectedDate} onSelectionChanged={handleChangeSelectedDate} />
          </Box>
          {meetings &&
            <Box position={"relative"} h="100%" w={"100%"}>
              <Flex
                as="button"
                position={"absolute"}
                left={"0px"}
                w={"24px"}
                h={"100%"}
                borderRadius={"5px"}
                alignItems={"flex-start"}
                pt={"30px"}
                zIndex={2}
                onClick={handleClickPrevArrow}
                _hover={{ background: "#fafafa", }}
              >
                <Icon as={MdChevronLeft} w="24px" h="24px" display={selectedMeetingIndex > 0 ? 'auto' : 'none'} />
              </Flex>
              {variant === 'full' ? <MeetingDetailsCard
                prospect={null}
                variant={"dealOverview"}
                meetingData={selectedMeetingData}
                lastMeetingId={null}
                height={"100%"}
                width={"100%"}
                px="30px"
                boxShadow={'none'}
              /> :
                <MiniMeetingCard meetingData={selectedMeetingData} />}
              <Flex
                as="button"
                position={"absolute"}
                top={"0px"}
                right={"0px"}
                w={"24px"}
                h={"100%"}
                pt={"30px"}
                borderRadius={"5px"}
                alignItems={"flex-start"}
                zIndex={2}
                onClick={handleClickNextArrow}
                _hover={{ background: "#fafafa", }}
              >
                <Icon as={MdChevronRight} w="24px" h="24px" display={selectedMeetingIndex < meetings.length - 1 ? "auto" : "none"} />
              </Flex>
            </Box>
          }
        </Flex>
        {meetings.length > 0 && <Flex justifyContent="space-between" align="center" display={'none'}>

          <Box position={"relative"} w="100%" h="100%" pt={"20px"}>
            {moment(new Date()).isAfter(selectedMeetingData.date) && selectedMeetingData.generatedSummary?.length &&
              <MeetingSummary
                meetingData={selectedMeetingData}
                pt="20px" variant={"withTitle"} />
            }
          </Box>
        </Flex>}
      </Flex>
    </Card>
  );
}


function MiniMeetingCard(props: { meetingData: Meeting, [x: string]: any }) {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');
  const linkColor = useColorModeValue("#0F738C", "white");
  const { meetingData, ...rest } = props;
  if (!meetingData) return (<Flex h={"100%"} flexDir="column" fontSize={"16px"} px={"35px"} py={"20px"}>
    <Text minW={'fit-content'}>{"No future meetings scheduled"}</Text>
  </Flex>)
  let meetingLink = null
  try { meetingLink = new URL(meetingData.meetingLink) } catch (e) { console.log(e) }
  let attendees: any[] = []
  if (meetingData?.attendees) {
    attendees = meetingData.attendees?.map((attendee) => attendee.name ?? attendee)
  } else if (meetingData?.attendeesEmails) {
    attendees = meetingData?.attendeesEmails
  }
  const dateAndTime = getFormattedDateTime(meetingData)?.split(',')
  let dateAndTimeShortString: any = null
  try { dateAndTimeShortString = dateAndTime[0].split(' ')[0].slice(0, 3) + ' ' + dateAndTime[0].split(' ')[1] + ', ' + dateAndTime[2] }
  catch (e) { console.log(e) }

  return (
    <Flex>
      <LinkBox as="article" w="100%" h={'100%'} px={'25px'}>
        {meetingData &&
          <LinkOverlay
            as={RouterLink}
            to={{ pathname: `/meetings/overview/${meetingData._id}`, }}
          />}
        <Flex h={"100%"} flexDir="column" fontSize={"16px"} px={"10px"} py={"20px"}>
          <Stack h="100%" alignItems={"start"} spacing={2}>
            <Flex flexDir={"column"} maxW={'400px'} w={'100%'}>
              <Flex justifyContent={'space-between'}>
                {meetingData && <Text isTruncated fontWeight={'semibold'} color={textColor} pr={'10px'}>{meetingData.name}</Text>}
                <Text minW={'fit-content'}>{dateAndTimeShortString || "No future meetings scheduled"}</Text>
              </Flex>
              {attendees?.length >= 1 ?
                <Text isTruncated mr={'10px'} color={textColorTertiary}>{attendees.join(', ')}</Text> :
                <Text isTruncated mr={'10px'} color={textColorTertiary}>{meetingData?.deal?.name}</Text>
              }
              {meetingLink &&
                <Link href={meetingData.meetingLink} isExternal color={linkColor} >
                  {meetingLink.hostname}
                  <ExternalLinkIcon mx="4px" boxSize={"12px"} />
                </Link>
              }
            </Flex>
          </Stack>
        </Flex>
      </LinkBox >
    </Flex>
  );
}
