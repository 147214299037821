import { createContext } from 'react';

export interface SidebarContextType {
  brandText: string,
  setBrandText: Function
}

export const SidebarContext = createContext<SidebarContextType>({
  brandText: '',
  setBrandText: () => { }
});
