import { Box, Flex, Stack, Button, LightMode } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import { NavLink } from 'react-router-dom';

function SidebarContent(props: { routes: RoutesType[] }) {
  const { routes } = props;
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: '16px', '2xl': '1px' }}>
          <Links routes={routes} />
          <NavLink to={{
            pathname: '/deals/create',
            state: {
              routeName: 'Add new deal'
            }
          }}>
            <LightMode>
              <Button
                colorScheme='brand'
                variant='outline'
                p='15px 40px'
                fontSize='sm'
                fontWeight='500'
                ms={{ base: '0', md: 'auto' }}
                mb={{ base: '20px', md: '0' }}
                me={{ base: '0', md: '20px' }}
                mt="24px"
                _hover={{ bg: 'whiteAlpha.100' }}
                _focus={{ bg: 'transparent' }}
                _active={{ bg: 'transparent' }}
                width="90%">
                Create Deal
              </Button>
            </LightMode>
          </NavLink>
        </Box>
      </Stack>
    </Flex >
  );
}

export default SidebarContent;
