import { Box, Button, Flex, Icon, Radio, Tab, Td, Text, Th, Tr, useColorModeValue, useTab } from "@chakra-ui/react";
import React from "react";
import { LinkedinIcon, TwitterIcon, FacebookIcon, CrunchbaseIcon } from 'components/icons/Icons';
import { ExternalLinkIcon } from '@chakra-ui/icons'
interface TabButtonProps {
  label: string;
}
//TODO(KRIS) : clear selection on back
export const TabButton = React.forwardRef<HTMLButtonElement, TabButtonProps>((props, ref) => {
  const { label, ...rest } = props;
  const tabProps = useTab({ ...props, ref })
  const isSelected = !!tabProps['aria-selected']
  return (
    <Tab
      ref={ref}
      px={'0px'}
      cursor='auto'
      w={'100%'}
      {...rest} {...tabProps} isDisabled>
      <Flex
        w={'100%'}
        h={'100%'}
        direction='column'
        justify='flex-start'
        align='center'
        position='relative'
        _before={{
          content: "''",
          width: '100%',
          height: '3px',
          bg: isSelected ? 'white' : 'gray.400',
          left: '0',
          top: '6px',
          position: 'absolute',
          bottom: '38px',
          transition: 'all .3s ease',
        }}
      >
        <Box
          zIndex='1'
          border='2px solid'
          borderColor={isSelected ? 'white' : 'gray.400'}
          bg='linear-gradient(135deg, #04647C 0%, #00A6D1 100%)'
          w='16px'
          h='16px'
          mb='8px'
          borderRadius='50%'
        />
        <Text px={'5px'} color={isSelected ? 'white' : 'secondaryGray.600'} fontWeight={isSelected ? 'bold' : 'normal'}>
          {label}
        </Text>
      </Flex>
    </Tab>
  );
});


export function TableHeader(props: {
  header: string,
  index: number,
  [x: string]: any;
}) {
  const { header, index, ...rest } = props
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Th
      key={index}
      borderColor={borderColor}
      cursor='pointer'
      pr='0px'
      {...rest}
    >
      <Flex
        key={index}
        justifyContent='space-between'
        align='center'
        fontSize={{ sm: '10px', lg: '12px' }}
        color='gray.400'>
        {header}</Flex>
    </Th>)
};

export function TableElement(props: {
  contents: any,
  [x: string]: any;
}) {
  const { contents, ...rest } = props
  return (
    <Td
      fontSize={{ sm: '14px' }}
      borderColor='transparent'
      {...rest} >
      {contents}
    </Td>
  )
}

export function TableRowWithRadio(props: {
  variant: 'removeLeftPadding' | 'default'
  rowContents: any[],
  radioValue: string
}) {
  const { rowContents, radioValue, variant } = props
  return (
    <Tr key={radioValue} w={'100%'}>
      {rowContents.map((content, index) => (
        <TableElement contents={content} key={radioValue + content + index} pr='5px' pl={index > 0 || variant == 'default' ? 'auto' : '0px'} />
      ))}
      <TableElement contents={<Radio value={radioValue} colorScheme={'brandScheme'} key={radioValue} />} />
    </Tr>
  )
}
TableRowWithRadio.defaultProps = { variant: 'default' }

function BaseButton(props: {
  buttonText: string,
  onClick: React.MouseEventHandler<HTMLButtonElement>
  [x: string]: any;
}) {
  const { buttonText, onClick, ...rest } = props
  return (
    <Button
      variant={"darkBrand"}
      fontSize='sm'
      borderRadius='16px'
      w={{ base: '128px', md: '148px' }}
      h='46px'
      onClick={onClick}
      {...rest} >
      {buttonText}
    </Button>
  )
}

export function BackButton(props: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  buttonText?: string
  [x: string]: any;
}) {
  const { buttonText, onClick, ...rest } = props
  return (
    <BaseButton
      variant={'light'}
      onClick={onClick}
      buttonText={buttonText || 'Back'}
      {...rest} />
  )
}
export function NextButton(props: {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  buttonText?: string
  [x: string]: any;
}) {
  const { buttonText, onClick, ...rest } = props
  return (
    <BaseButton
      variant={'darkBrand'}
      onClick={onClick}
      buttonText={buttonText || 'Next'}
      {...rest} />
  )
}


export function ProfileIcon(props: {
  profile: string,
}) {
  const { profile } = props;
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  if (profile.includes('linkedin')) {
    return <Icon as={LinkedinIcon} color={brandColor} boxSize='18px' />
  } else if (profile.includes('twitter')) {
    return <Icon as={TwitterIcon} bg={brandColor} color={'white'} boxSize='18px' borderRadius={'50%'} />
  } else if (profile.includes('facebook')) {
    return <Icon as={FacebookIcon} bg={brandColor} color={'white'} boxSize='18px' borderRadius={'50%'} />
  } else if (profile.includes('crunchbase')) {
    return <Icon as={CrunchbaseIcon} bg={brandColor} color={'white'} boxSize='18px' borderRadius={'5px'} />
  } else {
    return <ExternalLinkIcon color={brandColor} boxSize='18px' borderRadius={'5px'} />;
  }

}

export function cleanURL(url: string) {
  // TODO: use URL class or a better method
  if (!url.startsWith('http')) {
    url = 'https://' + url;
  }
  return url;
}


