import { useState } from 'react';
import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Divider,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  border,
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import IconHeaderList from './IconHeaderList';
import { SituationIcon, ProblemIcon, ImpactIcon, CriticalEventIcon, DecisionIcon } from 'components/icons/Icons';
import { Deal, Insight, DealSummaryContent, QualificationChecklistContent, ProductInsightsContent } from 'views/deals/Deal';
import { ProgressItem } from './ProgressItem';
import { VSeparator } from 'components/separator/Separator';
import { productItems } from 'views/products/productData';
import InsightSectionWithFeedback from '../../../../components/feedback/InsightSectionWithFeedback'

//formerly DealSummaryContent
function QualificationChecklistTab(props: {
  qualificationContent: QualificationChecklistContent,
}) {
  const { qualificationContent } = props;
  if (!qualificationContent) return <>Info not generated yet</>
  return (
    <Box>
      <InsightSectionWithFeedback
        insights={qualificationContent.situation}
        sectionHeader={'Situation'}
        icon={SituationIcon} mb={'15px'}
      />
      <InsightSectionWithFeedback
        insights={qualificationContent.problem}
        sectionHeader={'Problem'}
        icon={ProblemIcon} mb={'15px'}
      />
      <InsightSectionWithFeedback
        insights={qualificationContent.impact}
        sectionHeader={'Impact'}
        icon={ImpactIcon} mb={'15px'}
      />
      <InsightSectionWithFeedback
        insights={qualificationContent.criticalEvent}
        sectionHeader={'Critical Event'}
        icon={CriticalEventIcon} mb={'15px'}
      />
      <InsightSectionWithFeedback
        insights={qualificationContent.decision}
        sectionHeader={'Decision'}
        icon={DecisionIcon} mb={'15px'}
      />
    </Box>
  );
}

function DealSummaryTab(props: {
  dealSummaryContent: DealSummaryContent,
}) {
  const { dealSummaryContent } = props;
  if (!dealSummaryContent) return <>Info not generated yet</>
  return (
    <Box>
      <InsightSectionWithFeedback
        insights={dealSummaryContent.executiveSummary}
        sectionHeader={'Executive Summary'}
        icon={SituationIcon}
      />
      <InsightSectionWithFeedback
        insights={dealSummaryContent.businessContext}
        sectionHeader={'Business Context'}
        icon={SituationIcon}
      />
      <InsightSectionWithFeedback
        insights={dealSummaryContent.complications}
        sectionHeader={'Complications'}
        icon={ProblemIcon}
      />
      <InsightSectionWithFeedback
        insights={dealSummaryContent.solutionOverview}
        sectionHeader={'Solution Overview'}
        icon={ImpactIcon}
      />
      <InsightSectionWithFeedback
        insights={dealSummaryContent.targetOutcome}
        sectionHeader={'Target Outcomes'}
        icon={CriticalEventIcon}
      />
    </Box>
  );
}

function ProductInsightsTab(props: {
  insights: ProductInsightsContent
}) {
  const { insights } = props
  const textColor = useColorModeValue('navy.700', 'white');
  if (!insights) return <>Info not generated yet</>
  return (
    <Box>
      <InsightSectionWithFeedback
        insights={insights.customerExpectations}
        sectionHeader={'Customer Expectations'}
        icon={SituationIcon}
      />
      <InsightSectionWithFeedback
        insights={insights.featureRequests}
        sectionHeader={'Feature Requests'}
        icon={ProblemIcon}
      />
    </Box>
  );
}

function DealProgressTab(props: {
  dealProgressItems: Insight[]
}) {
  const { dealProgressItems } = props
  const textColor = useColorModeValue('navy.700', 'white');
  if (!dealProgressItems) return <>Info not generated yet</>;
  return (
    <>
      <Box px={'15px'}>
        {dealProgressItems.map((item, index) => (
          <Flex key={item.insightId} mb={'15px'}>
            {index % 2 === 0 && (
              <>
                {item && <ProgressItem
                  icon={DecisionIcon}
                  progressContent={item.content}
                  variant={'left'}
                  w={'49%'}
                  pr={'15px'}
                />}
                <Center height='50px' alignSelf={'center'}>
                  <Divider orientation='vertical' borderWidth={'1px'} borderStyle={'dashed'} borderColor={'#0F738C'} />
                </Center>
                {index + 1 < dealProgressItems.length && dealProgressItems[index + 1] && (
                  <ProgressItem
                    icon={DecisionIcon}
                    progressContent={dealProgressItems[index + 1].content}
                    variant={'right'}
                    w={'49%'} pl={'15px'}
                  />
                )}
              </>
            )}
          </Flex>
        ))}
      </Box>
    </>
  );
}

function SalesPlaybookTab() {
  const textColor = useColorModeValue('navy.700', 'white');
  return (
    <Flex>
      <Flex w='876px'>
        <Accordion w='100%' allowMultiple pl='15px'>
          {productItems.map((item: any, index: any) => (
            <AccordionItem key={index} borderBottomWidth={'0px'} borderTopColor={index === 0 ? 'transparent' : 'default'}>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    {item.header}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{item.content}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
    </Flex>
  );
}

function DealOverviewTab(props: { activeTab: number; index: number, name: any; }) {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColorTertiary = useColorModeValue('secondaryGray.600', 'secondaryGray.500');
  const { activeTab, index, name, } = props;
  return (
    <Tab
      key={index}
      pb='0px'
      flexDirection='column'
      me='10px'
      bg='unset'
      _selected={{
        bg: 'none'
      }}
      _focus={{ border: 'none' }}
      minW='max-content'>
      <Flex align='center'>
        <Text
          color={activeTab == index ? textColor : textColorTertiary}
          fontSize='lg'
          fontWeight='500'>
          {name}
        </Text>
      </Flex>
      <Box
        height='4px'
        w='100%'
        transition='0.1s linear'
        bg={activeTab == index ? 'brand.500' : 'transparent'}
        mt='15px'
        borderRadius='30px'
      />
    </Tab>
  );
}

export default function DealSummaryCard(props: { deal: Deal, [x: string]: any }) {
  const { deal } = props;
  let [activeTab, setActiveTab] = useState<number>(0);
  if (!deal) return null;
  return (
    <Card>
      <Tabs variant='soft-rounded' colorScheme='brandTabs' mb='60px' index={activeTab} onChange={(index) => setActiveTab(index)}>
        <TabList overflowX={{ sm: 'scroll', lg: 'unset' }}>
          <Flex>
            <DealOverviewTab activeTab={activeTab} index={0} name='Qualification Checklist' />
            <DealOverviewTab activeTab={activeTab} index={1} name='Deal Summary' />
            <DealOverviewTab activeTab={activeTab} index={2} name='Deal Progress' />
            <DealOverviewTab activeTab={activeTab} index={3} name='Sales Playbook' />
            <DealOverviewTab activeTab={activeTab} index={4} name='Product Insights' />
          </Flex>
        </TabList>
        <TabPanels pt='10px'>
          <TabPanel px='0px'>
            <QualificationChecklistTab qualificationContent={deal.qualificationContent} />
          </TabPanel>
          <TabPanel px='0px'>
            <DealSummaryTab dealSummaryContent={deal.dealSummaryContent} />
          </TabPanel>
          <TabPanel px='0px'>
            <DealProgressTab dealProgressItems={deal.dealProgressContent} />
          </TabPanel>
          <TabPanel px='0px'>
            <SalesPlaybookTab />
          </TabPanel>
          <TabPanel px='0px'>
            <ProductInsightsTab insights={deal.productInsightsContent} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
}
