import { Flex, Text, useColorModeValue, UnorderedList, ListItem } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { LinkBox } from '@chakra-ui/react'
import { Account } from 'views/accounts/Account';
import InsightSectionWithFeedback from 'components/feedback/InsightSectionWithFeedback';

export function BulletSection(props: {
  list: string[],
  header: string,
  [x: string]: any
}) {
  const { list, header, ...rest } = props
  const textColor = useColorModeValue('#2B3674', 'white');
  if (!list || list.length < 1) return null
  return (
    <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px'>
      <Text fontWeight={'bold'} color={textColor}>{header}</Text>
      <UnorderedList fontSize={'16px'}>
        {list.map((point, index) => (<ListItem key={index} ml={2}>{point}</ListItem>))}
      </UnorderedList>
    </Flex>
  );
};

//TODO(KRIS) : should this be in the new accounts folder?
export default function AboutAccount(props: {
  variant: "prospectOverview" | "accountOverview"
  account: Account
  [x: string]: any
}) {
  const { variant, account, ...rest } = props;
  if (!account) return null
  return (
    <LinkBox as='article' w="100%">
      <Card
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        height={'100%'}
        px={'30px'}
        py={'30px'}
        {...rest}>
        <Flex w='100%' h={'100%'} alignItems={'flex-start'} flexDir='column' fontSize={'16px'} >
          <Text fontWeight={'bold'} fontSize={'18px'} mb='20px'>
            About {account.name}
          </Text>
          <Flex flexDir={'row'} w='100%' mb='30px'>
            <InsightSectionWithFeedback insights={account.strategicInitiatives} sectionHeader={'Strategic Initiatives'} />
          </Flex>
          <Flex flexDir={'row'} w='100%' mb='30px'>
            <InsightSectionWithFeedback insights={account.news} sectionHeader={'News'} />
          </Flex>
          <Flex flexDir={'row'} w='100%' mb='30px'>
            <InsightSectionWithFeedback insights={account.techStack} sectionHeader={'Tech Stack'} />
          </Flex>
          {variant == 'accountOverview' &&
            <>
              <Flex flexDir={'row'} w='100%' mb='30px'>
                <InsightSectionWithFeedback insights={account.interests} sectionHeader={'Interests'} />
              </Flex>
              <Flex flexDir={'row'} w='100%' mb='30px'>
                <InsightSectionWithFeedback insights={account.challenges} sectionHeader={'Challenges'} />
              </Flex>
            </>}
        </Flex>
      </Card>
    </LinkBox>
  );
}
