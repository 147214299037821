import { useState } from 'react';
import { Flex, Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';
import { productItems } from './productData';
import FileUploader from 'components/uploader/FileUploader';

export default function Products() {
  const [uploadedFiles, setUploadedFiles] = useState<{ name: string; size: number }[]>([]);

  const handleFileUploadSubmit = (data: { file_: FileList }) => {
    const { file_ } = data;

    if (file_ && file_.length > 0) {
      const file = file_[0];
      const updatedFiles = [...uploadedFiles, { name: file.name, size: file.size }];
      setUploadedFiles(updatedFiles);
    }
  };
  return (
    <Flex h='500px'
      pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex w='876px' px='13px'>
        <Accordion w='100%' allowMultiple pl='35px'>
          {productItems.map((item: any, index: any) => (
            <AccordionItem key={index} borderBottomWidth={'0px'} borderTopColor={index === 0 ? 'transparent' : 'default'}>
              <h2>
                <AccordionButton>
                  <Box as='span' flex='1' textAlign='left'>
                    {item.header}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{item.content}</AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Flex>
      <Flex w='603px' px='13px' flexDirection={'column'} ml={'32px'}>
        <Text fontSize={'xl'} fontWeight={'extrabold'} pl='24px' mb={'20px'}>
          Add Product info here:
        </Text>
        <Flex>
          <FileUploader
            onFormSubmit={handleFileUploadSubmit}
            accept='.DOC, .DOCX, .PPT, .PDF'
            multiple={false}
            buttonText={'Submit'} />
        </Flex>
        <Flex pl='24px' flexDirection={'column'} py='20px'>
          <Text fontSize={'xl'} fontWeight={'extrabold'} mb={'20px'}>
            Assets:
          </Text>
          <Flex pl='24px'>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li key={index}>
                  {file.name}
                </li>
              ))}
            </ul>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
