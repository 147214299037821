import { Flex, Text, useColorModeValue, UnorderedList, ListItem, Grid } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { LinkBox } from '@chakra-ui/react'
import { Prospect } from '../Prospect';
import InsightSectionWithFeedback from '../../../components/feedback/InsightSectionWithFeedback';

export function BulletSection(props: {
  list: string[],
  header: string,
  [x: string]: any
}) {
  const { list, header, ...rest } = props
  const textColor = useColorModeValue('#2B3674', 'white');
  if (!list || list.length < 1) return null;
  return (
    <Flex flexDir={'column'} flexGrow={1} flexBasis={0} mr='32px' {...rest}>
      <Text fontWeight={'bold'} color={textColor}>{header}</Text>
      <UnorderedList fontSize={'16px'}>
        {list.map((point, index) => (<ListItem key={index} ml={2}>{point}</ListItem>))}
      </UnorderedList>
    </Flex>);
};

export default function AboutContact(props: {
  prospect: Prospect,
  [x: string]: any
}) {
  const { prospect, ...rest } = props;
  if (!prospect) return null
  return (
    <LinkBox as='article' w={'100%'}>
      <Card
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        px='30px'
        py='30px'
        {...rest}
      >
        <Flex w={'100%'} mb={'20px'}>
          <Text fontWeight='bold' fontSize='18px'>
            About {prospect.name.split(' ')[0]}
          </Text>
        </Flex>
        <Grid w='100%' templateColumns='repeat(2, 1fr)' gap={6} fontSize='16px'>
          <InsightSectionWithFeedback
            insights={prospect.jobHistory?.slice(0, 3)}
            sectionHeader='Job History'
          />
          <InsightSectionWithFeedback
            insights={prospect.rolesAndResponsibilities?.slice(0, 3)}
            sectionHeader='Roles and Responsibilities'
          />
          <InsightSectionWithFeedback
            insights={prospect.prospectInterests?.slice(0, 3)}
            sectionHeader='Interests'
          />
          <InsightSectionWithFeedback
            insights={prospect.businessPriority?.slice(0, 3)}
            sectionHeader='Business Priority'
          />
          <InsightSectionWithFeedback
            insights={prospect.skillsets?.slice(0, 3)}
            sectionHeader='Skillsets'
          />
          <InsightSectionWithFeedback
            insights={prospect.challenges?.slice(0, 3)}
            sectionHeader='Challenges'
          />
        </Grid>
      </Card>
    </LinkBox>
  );
}
