import { Flex, Image, Link, Box } from '@chakra-ui/react';
import { HSeparator } from 'components/separator/Separator';
import WiseLogo2 from 'assets/svg/wise-logo-2.svg';


export function SidebarBrand() {

	return (
		<Flex alignItems='center' flexDirection='column' >
			<Box w='100%'>
				<Link fontSize={{ base: 'xx-large', md: 'xx-large' }} fontWeight='900' href='/' w='100%' display="flex">
					<Flex p='10px' justifyContent="center">
						<Image src={WiseLogo2} alt='Wise Logo' w='50%' />
					</Flex>
				</Link>
			</Box>
			<HSeparator mb='20px' />
		</Flex >
	);
}

export default SidebarBrand;
