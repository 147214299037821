import {
  Flex, Icon, Text, useColorModeValue, Image, Stack, LinkOverlay, LinkBox, Link, Avatar
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

import Card from 'components/card/Card';
import { LinkedinIcon } from 'components/icons/Icons';
import { TwitterIcon } from 'components/icons/Icons';
import { PhoneIcon } from 'components/icons/Icons';
import { EmailIcon } from 'components/icons/Icons';
import { RoleIcon } from 'components/icons/Icons';
import { Prospect } from '../Prospect';
import { cleanURL } from 'views/common/common';

import parsePhoneNumber from 'libphonenumber-js'

export default function ProspectCard(props: {
  prospect: Prospect,
  variant: "withLinkOverlay" | "noLinkOverlay"
  [x: string]: any
}) {
  const { prospect, variant, ...rest } = props;
  const brandColor = useColorModeValue('brand.500', 'brand.400');
  const textColor = useColorModeValue('#2B3674', 'white');
  const textColorSecondary = useColorModeValue('#44546F', 'white');
  const textColorTertiary = useColorModeValue('#A3AED0', 'white');

  if (!prospect) return null;
  const linkedinProfiles = prospect.profiles?.filter(profile => profile.network == 'linkedin')
  const linkedinUrl = linkedinProfiles ? linkedinProfiles[linkedinProfiles.length - 1]?.url : prospect.linkedin
  const twitterUrl = prospect.profiles?.find(profile => profile.network == 'twitter')?.url || prospect.twitter
  const email = prospect.email ? prospect.email : (prospect.emails?.length > 0 ? prospect.emails[0]?.address : null);
  console.log('prospect', prospect)
  //todo  compare number country to user country and show country code if different
  const phoneNumber = (prospect.phoneNumber && parsePhoneNumber(prospect.phoneNumber)) ? parsePhoneNumber(prospect.phoneNumber).formatNational() : prospect.phoneNumber;
  const roleString = prospect.prospectSegmentation?.role?.role ? prospect.prospectSegmentation.role.role.charAt(0) + prospect.prospectSegmentation.role.role.slice(1).toLowerCase() : null;
  return (
    <LinkBox as='article' w={'100%'}>
      <Card
        justifyContent='flex-start'
        alignItems='center'
        flexDirection='column'
        height={'289px'}
        px={'30px'}
        py={'30px'}
        {...rest}>
        <Flex w='100%' h={'100%'}>
          <Flex h={'100%'} w='50%' minW='275px' maxW='500px'>
            <Stack h='100%' w='100%' alignItems={'center'} >
              <Avatar mr="14px" src={prospect.pictureLink} borderRadius='full' boxSize='87px' mb={'6px'} />
              <Flex display={"flex"} justifyContent='center'>
                {variant == "withLinkOverlay" && <LinkOverlay as={ReactLink} to={{
                  pathname: `/prospects/overview/${prospect._id}`,
                  state: {
                    routeName: `${prospect.name}`
                  }
                }} />}
                <Text fontSize={'18px'} fontWeight={'bold'} flexGrow={1} color={textColor} textTransform={'capitalize'}>{prospect.name}</Text>
              </Flex>
              <Flex fontSize={'16px'}>
                <Text color={textColorTertiary} textAlign={'center'}>{`${prospect?.title} (${prospect?.account?.name})`}</Text>
              </Flex>
              <Flex gap={8} mt={'6px'}>
                {linkedinUrl && <Link href={cleanURL(linkedinUrl)} isExternal zIndex={1}>
                  <Icon as={LinkedinIcon} color={brandColor} boxSize='24px' />
                </Link>}
                {twitterUrl && <Link href={cleanURL(twitterUrl)} isExternal zIndex={1}>
                  <Icon as={TwitterIcon} color={'white'} boxSize='24px' bg={brandColor} borderRadius='12px' />
                </Link>}
              </Flex>
            </Stack>
          </Flex>
          <Flex pl={4} h={'100%'} w={'100%'} fontSize={'18px'} maxW={'calc(50% - 10px)'}>
            <Stack h='100%' w={'100%'} gap={'20px'} alignItems={'start'} color={textColorSecondary}>
              {prospect.phoneNumber && <Flex>
                <Icon as={PhoneIcon} color={textColorSecondary} boxSize={'30px'} bg='#ECECEC' borderRadius='15px' padding={'7px'} />
                <Flex fontWeight={'normal'} flexGrow={1} ml={'20px'}>{prospect.phoneNumber}</Flex>
              </Flex>}
              {prospect.email && <Flex maxW={'calc(100% - 20px)'}>
                <Icon as={EmailIcon} color={textColorSecondary} boxSize={'30px'} bg='#ECECEC' borderRadius='15px' padding={'7px'} />
                <Text isTruncated fontWeight={'normal'} ml={'20px'}>{email}</Text>
              </Flex>}
              {roleString && <Flex>
                <Icon as={RoleIcon} color={textColorSecondary} boxSize={'30px'} bg='#ECECEC' borderRadius='15px' padding={'7px'} />
                <Text fontWeight='normal' flexGrow={1} ml={'20px'}>{roleString}</Text>
              </Flex>}
            </Stack>
          </Flex>
        </Flex>
      </Card>
    </LinkBox >
  );
}
