import { Box, ComponentWithAs, Flex, Icon, IconProps, Text, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Deal, DealProgressContent } from 'views/deals/Deal'

export function ProgressItem(props: {
  icon: any
  progressContent: DealProgressContent
  variant: 'left' | 'right'
  [x: string]: any
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const { icon, progressContent, variant, ...rest } = props;
  const alignRule = variant == 'left' ? 'start' : 'end';
  if (!progressContent) return
  return (
    < Flex w='100%' py='10px' {...rest} justifyContent={alignRule}>
      <Flex direction={'column'} mx={'15px'}>
        <Text color={textColor} fontWeight='700' mb={'8px'} align={alignRule}>
          {progressContent.oneLineSummary}
        </Text>
        <Text color={textColor} fontSize='xs' me='6px' align={alignRule}>
          {progressContent.date}
        </Text>
      </Flex>
    </Flex >
  );
}
