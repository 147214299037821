/* eslint-disable */
// Chakra Imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import NavbarLinksAdmin from 'components/navbar/NavbarLinksAdmin';

export default function NavbarAdmin(props: {
  secondary: boolean;
  brandText: string;
  logoText: string;
  fixed: boolean;
  onOpen: (...args: any[]) => any;
}) {
  const { secondary, brandText } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant)
  let mainText = useColorModeValue('navy.700', 'white');
  let navbarPosition = 'fixed' as const;
  let navbarFilter = 'none';
  let navbarBackdrop = 'blur(20px)';
  let navbarShadow = 'none';
  let navbarBg = useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)');
  let navbarBorder = 'transparent';
  let secondaryMargin = '0px';
  let paddingX = '15px';
  let gap = '0px';

  return (
    <Box
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition='center'
      backgroundSize='cover'
      borderRadius='16px'
      borderWidth='1.5px'
      borderStyle='solid'
      transitionDelay='0s, 0s, 0s, 0s'
      transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
      transition-property='box-shadow, background-color, filter, border'
      transitionTimingFunction='linear, linear, linear, linear'
      alignItems={{ xl: 'center' }}
      display={secondary ? 'block' : 'flex'}
      minH='75px'
      justifyContent={{ xl: 'center' }}
      lineHeight='25.6px'
      mx='auto'
      mt={secondaryMargin}
      pb='8px'
      right={{ base: '15px', md: '30px', lg: '30px', xl: '30px' }}
      px={{
        sm: paddingX,
        md: paddingX
      }}
      ps={{
        xl: '12px'
      }}
      pt='8px'
      top={{ base: '12px', md: '16px', xl: '18px' }}
      w={{
        base: 'calc(100% - 30px)',
        md: 'calc(100% - 60px)',
        lg: 'calc(100% - 60px)',
        xl: 'calc(100% - 280px)',
        '2xl': 'calc(100% - 280px)'
      }}>
      <Flex
        w='100%'
        flexDirection={'row'}
        alignItems={'center'}
        mb={gap}>
        <Box mb={{ sm: '8px', md: '0px' }}>
          {/* Here we create navbar brand, based on route name */}
          <Text
            color={mainText}
            bg='inherit'
            borderRadius='inherit'
            fontWeight='bold'
            fontSize='34px'
            _hover={{ color: { mainText } }}
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent'
            }}
            _focus={{
              boxShadow: 'none'
            }}>
            {brandText}
          </Text>
        </Box>
        <Box ms='auto' w={'unset'}>
          <NavbarLinksAdmin
            secondary={props.secondary}
          />
        </Box>
      </Flex>
    </Box>
  );
}
