import React from 'react';
import { Flex, Link, Text, Image } from '@chakra-ui/react';
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

const AboutPage = () => {
  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} minHeight="100vh">
      <NavBar />
      <Flex alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} flexDir='column' pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>About Us</Text>
        </Flex>
        <Flex >
          <Text fontSize={'20px'}>...</Text>
        </Flex>
      </Flex>
      <FooterNavBar />
    </Flex >
  );
};

export default AboutPage;
