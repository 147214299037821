import { useEffect } from "react";
import {
  Box,
  Flex,
  Text,
  Grid,
  useColorModeValue,
  useClipboard,
  useToast,
  Image,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

import Card from "components/card/Card";
import { ActionItem, Meeting } from "views/deals/Deal";
import CopyIcon from "assets/svg/copy-light.svg";
import ProspectListCard from "views/deals/overview/components/ProspectListCard";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import InsightSectionWithFeedback from "components/feedback/InsightSectionWithFeedback";
import { getFormattedDateTime } from "views/deals/overview/components/MeetingDetailsCard";

export default function MeetingDetails(props: { meetingDetail: Meeting }) {
  const textColor = useColorModeValue("navy.700", "white");
  const linkColor = useColorModeValue("#0F738C", "white");
  const attendeeNameColor = useColorModeValue("secondaryGray.900", "white");
  const attendeeRoleColor = useColorModeValue("secondaryGray.600", "white");
  const { onCopy, hasCopied } = useClipboard("");
  const toast = useToast();
  const { meetingDetail } = props;
  useEffect(() => {
    if (hasCopied) {
      toast({
        title: "Copied!",
        status: "success",
        duration: 1000,
        isClosable: true,
        position: "top",
      });
    }
  }, [hasCopied, toast]);

  if (!meetingDetail) return null

  const { date, startTime, actionItems, agenda, meetingLink, attendees, dealId } = meetingDetail;
  const agendaText = moment(new Date()).isAfter(date) ? "Topics discussed" : "Agenda";

  return (
    <Flex direction="column">
      <Card mb={{ base: "20px" }}>
        <Grid templateColumns={{ base: ".5fr 1.5fr 1.5fr 1fr" }}>
          <Box>
            <Text color={textColor} fontSize={"lg"} fontWeight="700">
              Deal
            </Text>
            <Text color="#3D4B9B" fontSize={"md"}>
              <Link
                as={RouterLink}
                to={`/deals/overview/${dealId}`}
                color="#0F738C"
                fontSize={"md"}>
                {meetingDetail.deal.name}
              </Link>
            </Text>
          </Box>
          <Box>
            <Text color={textColor} fontSize={"lg"} fontWeight="700">
              Meeting Details
            </Text>
            <Text color="#3D4B9B" fontSize={"md"}>
              <Text fontWeight={"normal"}>{getFormattedDateTime(meetingDetail) || "No future meetings scheduled"}</Text>
            </Text>
          </Box>
          {meetingLink && <Box>
            <Text color={textColor} fontSize={"lg"} fontWeight="700">
              Meeting link
            </Text>
            <Link href={meetingLink} isExternal color={linkColor} >
              {new URL(meetingLink).hostname}
              <ExternalLinkIcon mx="4px" boxSize={"12px"} />
            </Link>
          </Box>}
          <Box>
            <Text color={textColor} fontSize={"lg"} fontWeight="700">
              One Click Call
            </Text>
            <Flex alignItems="start">
              <Text as="span" color="#3D4B9B" fontSize={"md"}>
                202-432-2990
              </Text>
              <Box as="button" onClick={onCopy}>
                <Image src={CopyIcon} alt="Copy Icon" />
              </Box>
            </Flex>
          </Box>
        </Grid>
      </Card>

      <Flex dir='column' mr={'-15px'}>
        <Flex flexWrap={"wrap"} w={'100%'} ml={"-30px"} mr={'-15px'}>
          <Flex
            mb={"15px"}
            mr={"-15px"}
            ml={"30px"}
            minW={"600px"}
            grow={1}
            shrink={1}
            basis={"600px"} >
            {attendees && <ProspectListCard prospects={attendees} headerText={'Attendees'} variant={'accountOverview'} />}
            <Card ml="15px">
              <InsightSectionWithFeedback insights={agenda} sectionHeader={agendaText} />
            </Card>
          </Flex>

          <Flex
            mb={"15px"}
            ml={"30px"}
            mr={"-15px"}
            minW={"525px"}
            grow={1}
            shrink={1}
            basis={"525px"}
          >
            <Card w='100%'>
              {actionItems &&
                <InsightSectionWithFeedback insights={actionItems} sectionHeader={'Action Items'} />
              }
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
