import { Flex, Text, Input, Select, Box, IconButton, InputGroup, InputRightAddon, FormControl, FormErrorMessage, Popover, PopoverTrigger, PopoverContent, PopoverHeader, PopoverBody, useDisclosure } from '@chakra-ui/react';
import { DealPhase, Deal } from 'views/deals/Deal';
import { useState } from 'react';
import { BackButton, NextButton } from 'views/common/common';
import MeetingCalendar from 'views/deals/overview/components/MeetingCalendar';
import { CalendarIcon } from '@chakra-ui/icons';
import { dateToYYYYMMDD, timestampToDate } from '../Common';
const editDealUrl = "https://inwise-node-functions2.azurewebsites.net/api/editDealCard?code=UiYeqwqbR-2pXxDCsIsZUCjaCg87tN8zRq3vBOvoQEymAzFu7zyBRg%3D%3D";

export default function DealCardEdit(props: {
  deal: Deal,
  onEditCancelled: () => void,
  onEditSaved: (newValues: any) => void,
  [x: string]: any,
}) {
  const { deal, onEditCancelled, onEditSaved, ...rest } = props;
  if (!deal) return null;
  const info = deal.dealCardInfo;
  const isClosed = info?.stage?.content == DealPhase.Closed;

  const lastInteractionDate = info?.daysSinceLastInteraction?.content ? timestampToDate(info?.daysSinceLastInteraction?.content) : null
  const [selectedDate, onSelectedDateChange] = useState((lastInteractionDate && !isNaN(lastInteractionDate.valueOf())) ? lastInteractionDate : null);

  const [editedValues, setEditedValues] = useState({
    stage: info?.stage?.content,
    likelihoodToClose: info?.likelihoodToClose?.content,
    estimatedClose: info?.estimatedClose?.content,
    sentiment: info?.sentiment?.content,
    size: info?.size?.content,
    daysSinceLastInteraction: info?.daysSinceLastInteraction?.content
  });

  const [inputError, setInputError] = useState('');

  function handleEditChange(field: string, value: string) {

    if (field === 'timeToClose') {
      const numericValue = Number(value);
      if (numericValue >= 1 && numericValue <= 365) {
        setInputError('');
      } else {
        setInputError('Value must be between 1 and 365');
      }
      setEditedValues(prevValues => ({
        ...prevValues,
        [field]: value,
      }));
    } else {
      setEditedValues(prev => ({ ...prev, [field]: value }));
    }
  }

  const handleChangeSelectedDate = async (newValue: Date) => {
    onSelectedDateChange(newValue);
    handleEditChange('daysSinceLastInteraction', newValue.toDateString());
  };

  const handleCancel = () => {
    onEditCancelled();
    setInputError('')
  }

  const handleSave = () => {
    onEditSaved({ ...editedValues, ['daysSinceLastInteraction']: selectedDate ? dateToYYYYMMDD(selectedDate) : 'Not analyzed yet' });
    handleEditDeal();
  }

  const handleEditDeal = async () => {
    let updateContent: {
      stage?: string,
      likelihoodToClose?: string,
      estimatedClose?: string,
      sentiment?: string,
      size?: string,
      daysSinceLastInteraction?: string
    } = {}

    const date = selectedDate?.getTime()?.toString()
    if (editedValues?.stage != info?.stage?.content) updateContent.stage = editedValues.stage;
    if (editedValues?.likelihoodToClose != info?.likelihoodToClose?.content) updateContent.likelihoodToClose = editedValues.likelihoodToClose;
    if (editedValues?.estimatedClose != info?.estimatedClose?.content) updateContent.estimatedClose = editedValues.estimatedClose;
    if (editedValues?.sentiment != info?.sentiment?.content) updateContent.sentiment = editedValues.sentiment;
    if (editedValues?.size != info?.size?.content) updateContent.size = editedValues.size;
    if (date && date != info?.daysSinceLastInteraction?.content) updateContent.daysSinceLastInteraction = date;

    try {
      const response = await fetch(editDealUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ dealID: deal._id, ...updateContent }),
      });
      if (!response.ok) {
        console.error('Failed to create deal');
        return;
      }
    }
    catch (error) { console.error('Error during deal edit:', error) }
  }

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <>
      <Select value={editedValues.stage} onChange={(e) => handleEditChange('stage', e.target.value)}>
        {Object.values(DealPhase).map((phase) => (<option key={phase} value={phase}>{phase}</option>))}
      </Select>
      {!isClosed && <Select value={editedValues.likelihoodToClose} onChange={(e) => handleEditChange('likelihoodToClose', e.target.value)} >
        {['Low', 'Medium', 'High'].map((value) => (<option key={value} value={value}>{value}</option>))}
      </Select>}
      <FormControl isInvalid={inputError !== ''}>
        <InputGroup>
          <Input type="number" min="1" max="365" value={editedValues.estimatedClose} onChange={(e) => handleEditChange('estimatedClose', e.target.value)} />
          <InputRightAddon>days</InputRightAddon>
        </InputGroup>
        {inputError && <FormErrorMessage>{inputError}</FormErrorMessage>}
      </FormControl>
      <Select value={editedValues.sentiment} onChange={(e) => handleEditChange('sentiment', e.target.value)} >
        {['Low', 'Medium', 'High'].map((value) => (<option key={value} value={value}>{value}</option>))}
      </Select>
      <Select value={editedValues.size} onChange={(e) => handleEditChange('size', e.target.value)}>
        {['Small', 'Average', 'Large'].map((size) => (<option key={size} value={size}>{size}</option>))}
      </Select>

      <Flex alignItems="center" w={'100%'} justifyContent={'space-between'} px={'4'}>
        <Text>
          {selectedDate ? dateToYYYYMMDD(selectedDate) : 'Not analyzed yet'}
        </Text>
        <IconButton
          icon={<CalendarIcon />}
          onClick={onToggle}
          aria-label='Open Calendar'
        />
      </Flex>
      {isOpen && (
        <Popover
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={onClose}
          placement='right'>
          <PopoverTrigger><Flex></Flex></PopoverTrigger>
          <PopoverContent width={'300px'}>
            <PopoverBody >
              <Box
                alignItems="flex-start"
                mr="20px"
                boxShadow={"none"}
                minW={'275px'}
              >
                <MeetingCalendar meetings={null} selectedDate={selectedDate} onSelectionChanged={handleChangeSelectedDate} />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover >
      )}
      <Flex justifyContent="flex-end" mb={4} w={'100%'}>
        <BackButton mr={2} onClick={handleCancel} buttonText={'Cancel'} w={'100px'} />
        <NextButton onClick={handleSave} buttonText={'Save'} w={'100px'} isDisabled={inputError} />
      </Flex>
    </>
  );
}
