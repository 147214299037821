import SearchAndCreate from "views/common/SearchAndCreate";

//todo: should we consider using render instead of component in our routes so we can pass props to the component? 
//Either that or use location or routeMatch to see what page we're on and then conditionally render
export default function ProspectsCreate() {

  return (
    <>
      <SearchAndCreate variant='createDeal' />
    </>
  );
}
