import { Flex } from '@chakra-ui/react';
import TodoList from './components/TodoList';
import Dashboard from './components/Dashboard';
import { useLocation } from 'react-router-dom';
import { LocationProps, } from 'views/deals/Deal'
import DashboardCalendar from './components/DashboardCalendar';

export default function DealsDashboard() {
	const location = useLocation() as LocationProps
	const dealSummaries = location?.state?.dealSummaries;
	const onDealSummariesUpdated = location?.state?.onDealSummariesUpdated;

	return (
		<Flex pt={{ base: '130px', md: '80px', xl: '80px' }} h='max-content'>
			<Flex w='90%'>
				<Dashboard
					dealSummaries={dealSummaries}
					onDealSummariesUpdated={onDealSummariesUpdated}
				/>
			</Flex>
			<Flex h='100%' p={'10px'} flexDir='column' w={'450px'} minW={'450px'} position={'sticky'} right={0}>
				<DashboardCalendar />
				<TodoList />
			</Flex>
		</Flex>
	);
}
