import {
  Flex, Text, useColorModeValue, LinkOverlay, LinkBox, Box
} from '@chakra-ui/react';
import { Link as ReactLink } from 'react-router-dom';

import Card from 'components/card/Card';
import { Account } from 'views/accounts/Account';
import { HSeparator } from 'components/separator/Separator';
import { DealStageItem } from 'views/deals/overview/components/DealStageCard';

// TODO(KRIS) : share this with deal
export default function AccountCard(props: {
  account: Account,
  variant: "allAccounts" | "accountOverview"
  [x: string]: any
}) {
  const { account, variant, ...rest } = props;
  const textColor = useColorModeValue('#2B3674', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const vertical = variant == 'accountOverview'
  if (!account) return null;
  return (
    <LinkBox as='article' w='100%'>
      <Card
        justifyContent='flex-start'
        alignItems='flex-start'
        minW='550px'
        p={'30px'}
        pb={'15px'}
        {...rest}>
        <Flex flexDirection='column' height='100%' w='100%'>
          {variant == 'allAccounts' && <Flex flexDirection='column' width={'100%'}>
            <LinkOverlay as={ReactLink} to={{
              pathname: `/accounts/overview/${account._id}`,
              state: {
                routeName: `${account.name}`
              }
            }}>
              <Text fontSize={'2xl'} fontWeight={'extrabold'} justifyContent='flex-start' textTransform={'capitalize'}>{account.name}</Text>
            </LinkOverlay>
            <HSeparator mb='10px' />
          </Flex>}
          <Flex justify='flex-start' flexDir={vertical ? 'column' : 'row'} width={'100%'} grow={1} height={vertical ? 'initial' : '0px'}>
            <Box borderBottom={vertical ? '1px solid' : 'none'} borderColor={borderColor} minW={vertical ? 'initial' : '200px'}>
              <DealStageItem name={'CEO'} value={account.ceo} />
              <DealStageItem name={'Location'} value={'TBD'} />
              <DealStageItem name={'Founded'} value={account.foundedYear} />
              <DealStageItem name={'Size'} value={account.size.toString()} />
            </Box>
            <Box width={'100%'}>
              <Text ms='auto' h={'100%'} noOfLines={vertical ? 0 : 8}
                color={textColor}
                me='6px' mt={vertical ? '15px' : '5px'}
                ml={vertical ? '0' : '15px'}
              >
                {account.summaryOfWhatTheyDo?.charAt(0).toUpperCase() + account.summaryOfWhatTheyDo?.slice(1)}
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Card>
    </LinkBox >
  );
}
