import React, { useState } from 'react';
import { Flex, ListItem, OrderedList, Text, UnorderedList } from '@chakra-ui/react';
import NavBar from './components/InWiseNavBar';
import FooterNavBar from './components/InWiseFooter';

const PrivacyPage = () => {

  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'} pr={'100px'}>
      <NavBar />
      <Flex flexDir={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>Privacy Policy</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>At Inwise AI, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Inwise AI and how we use it.</Text>
        </Flex>
        <Flex pl={'5px'}>
          <Text fontSize={'20px'}>If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us.</Text>
        </Flex>
      </Flex>
      <Flex flexDir={'column'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'} pb={'100px'}>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text mb={'5px'}>Inwise AI (the“Company”) is committed to maintaining robust privacy protections for its users.  Our Privacy Policy (“Privacy Policy”) is designed to help you understand how we collect, use and safeguard the information you provide to us and to assist you in making informed decisions when using our Service.</Text>
          <Text mb={'5px'}>For purposes of this Agreement, “Site” refers to the Company`s website, which can be accessed at https://sellwisely.ai.</Text>
          <Text mb={'5px'}> “Service” refers to the Company`s services accessed via the Site, in which users can access sales playbooks at scale.</Text>
          <Text mb={'5px'}>The terms “we”,“us”, and “our” refer to the Company.</Text>
          <Text mb={'5px'}>“You” refers to you, as a user of our Site or our Service.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>I.              INFORMATION WE COLLECT</Text>
          <Text mb={'15px'}>We collect “Non-Personal Information” and “Personal Information.” <b>Non-Personal Information</b> includes information that cannot be used to personally identify you, such as anonymous usage data, general demographic information we may collect, referring/exit pages and URLs, platform types, preferences you submit and preferences that are generated based on the data you submit and number of clicks. <b>Personal Information</b> includes your email internet protocol (IP)addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information which you submit to us through the registration process at the Site
          </Text>
          <OrderedList>
            <ListItem mb={'20px'}>
              <Text fontWeight={"bold"} mb={'10px'}>Information collected via Technology</Text>
              <Text mb={'5px'}>To activate the Service you do not need to submit any Personal Information other than your email address. To use the Service thereafter, you need to submit further Personal Information name, email address, access to calendars and meeting clients. However, in an effort to improve the quality of the Service, we track information provided to us by your browser or by our software application when you view or use the Service, such as the website you came from (known as the “referring URL”), the type of browser you use, the device from which you connected to the Service, the time and date of access, and other information that does not personally identify you. We track this information using cookies, or small text files which include an anonymous unique identifier. Cookies are sent to a user’s browser from our servers and are stored on the user’s computer hard drive. Sending a cookie to a user’s browser enables us to collect Non-Personal information about that user and keep a record of the user’s preferences when utilizing our services, both on an individual and aggregate basis. For example, the Company may use cookies to collect the following information:</Text>
              <UnorderedList>
                <ListItem mb={'5px'}>user visits</ListItem>
                <ListItem mb={'5px'}>duration of visit</ListItem>
              </UnorderedList>
              <Text>The Company may use both persistent and session cookies; persistent cookies remain on your computer after you close your session and until you delete them, while session cookies expire when you close your browser.</Text>
            </ListItem>
            <ListItem mb={'20px'}>
              <Text fontWeight={"bold"} mb={'10px'}>Information you provide us by registering for an account</Text>
              <Text>In addition to the information provided automatically by your browser when you visit the Site, to become a subscriber to the Service you will need to create a personal profile. You can create a profile by registering with the Service and entering your email address, and creating a user name and a password. By registering, you are authorizing us to collect, store and use your email address in accordance with this Privacy Policy.</Text>
            </ListItem>
            <ListItem mb={'20px'}>
              <Text fontWeight={"bold"} mb={'10px'}>Children`s Privacy</Text>
              <Text>The Site and the Service are not directed to anyone under the age of 13. The Site does not knowingly collect or solicit information from anyone under the age of 13, or allow anyone under the age of 13 to sign up for the Service. In the event that we learn that we have gathered personal information from anyone under the age of 13 without the consent of a parent or guardian, we will delete that information as soon as possible. If you believe we have collected such information, please contact us at admin@inwise.ai</Text>
            </ListItem>
          </OrderedList>
        </Flex>

        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>II.            HOW WE USE AND SHARE INFORMATION</Text>
          <OrderedList>
            <ListItem mb={'20px'}>
              <Text fontWeight={"bold"} mb={'10px'}>Personal Information:</Text>
              <Text mb={'5px'}>Except as otherwise stated in this Privacy Policy, we do not sell, trade, rent or otherwise share for marketing purposes your Personal Information with third parties without your consent. We do share Personal Information with vendors who are performing services for the Company, such as the servers for our email communications who are provided access to user’s email address for purposes of sending emails from us. Those vendors use your Personal Information only at our direction and in accordance with our Privacy Policy.</Text>
              <Text mb={'5px'}>In general, the Personal Information you provide to us is used to help us communicate with you. For example, we use Personal Information to contact users in response to questions, solicit feedback from users, provide technical support, and inform users about promotional offers.</Text>
              <Text mb={'5px'}>We may share Personal Information with outside parties if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to meet any applicable legal process or enforceable governmental request; to enforce applicable Terms of Service, including investigation of potential violations; address fraud, security or technical concerns; or to protect against harm to the rights, property, or safety of our users or the public as required or permitted by law.</Text>
            </ListItem>
            <ListItem mb={'20px'}>
              <Text fontWeight={"bold"} mb={'10px'}>Non-Personal Information</Text>
              <Text mb={'5px'}>In general, we use Non-Personal Information to help us improve the Service and customize the user experience. We also aggregate Non-Personal Information in order to track trends and analyze use patterns on the Site. This Privacy Policy does not limit in any way our use or disclosure of Non-Personal Information and we reserve the right to use and disclose such Non-Personal Information to our partners, advertisers and other third parties at our discretion.</Text>
              <Text>In the event we undergo a business transaction such as a merger, acquisition by another company, or sale of all or a portion of our assets, your Personal Information may be among the assets transferred. You acknowledge and consent that such transfers may occur and are permitted by this Privacy Policy, and that any acquirer of our assets may continue to process your Personal Information as set forth in this Privacy Policy. If our information practices change at any time in the future, we will post the policy changes to the Site so that you may optout of the new information practices. We suggest that you check the Site periodically if you are concerned about how your information is used.</Text>
            </ListItem>
          </OrderedList>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>III.            HOW WE PROTECT INFORMATION</Text>
          <OrderedList>
            <Text mb={'5px'}>We implement security measures designed to protect your information from unauthorized access. Your account is protected by your account password and we urge you to take steps to keep your personal information safe by not disclosing your password and by logging out of your account after each use. We further protect your information from potential security breaches by implementing certain technological security measures including encryption, firewalls and secure socket layer technology. However, these measures do not guarantee that your information will not be accessed, disclosed, altered or destroyed by breach of such firewalls and secure server software. By using our Service, you acknowledge that you understand and agree to assume these risks.</Text>
          </OrderedList>
        </Flex>

        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>IV.             YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION</Text>
          <OrderedList>
            <Text mb={'5px'}>You have the right at any time to prevent us from contacting you for marketing purposes. When we send a promotional communication to a user, the user can opt out of further promotional communications by following the unsubscribe instructions provided in each promotional e-mail. You can also indicate that you do not wish to receive marketing communications from us by emailing us at admin@inwise.ai. Please note that notwithstanding the promotional preferences you indicate by either unsubscribing from the Site, we may continue to send you administrative emails including, for example, periodic updates to our Privacy Policy.</Text>
          </OrderedList>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>V.              LINKS TO OTHER WEBSITES</Text>
          <Text>As part of the Service, we may provide links to or compatibility with other websites or applications. However, we are not responsible for the privacy practices employed by those websites or the information or content they contain. This Privacy Policy applies solely to information collected by us through the Site and the Service. Therefore, this Privacy Policy does not apply to your use of a third party website accessed by selecting a link on our Site or via our Service. To the extent that you access or use the Service through or on another website or application, then the privacy policy of that other website or application will apply to your access or use of that site or application. We encourage our users to read the privacy statements of other websites before proceeding to use them.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>VI.             CHANGES TO OURPRIVACY POLICY</Text>
          <Text>The Company reserves the right to change this policy and our Terms of Service at any time. We will notify you of significant changes to our Privacy Policy by sending a notice to the primary email address specified in your account or by placing a prominent notice on our site. Significant changes will go into effect30 days following such notification. Non-material changes or clarifications will take effect immediately. You should periodically check the Site and this privacy page for updates.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>VII.            CONTACT US</Text>
          <Text mb={'5px'}>If you have any questions regarding this Privacy Policy or the practices of this Site, please contact us by sending an email to admin@inwise.ai.</Text>
          <Text>Last Updated: This Privacy Policy was last updated on 5/6/2024.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>VIII.           CONSENT</Text>
          <Text>By using our website, you hereby consent to our Privacy Policy and agree to its Terms and Conditions.</Text>
        </Flex>
        <Flex>
          <Text fontSize={'50px'}>Disclaimer</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>General Information</Text>
          <Text>The information provided by Inwise AI(“we,” “us” or “our”) on [website name] (the “Site”) and our mobile application is for general informational purposes only. All information on the Site and our mobile application is provided in good faith, however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our mobile application.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>External Links Disclaimer</Text>
          <Text>The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us. We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the site or any website or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Professional Disclaimer</Text>
          <Text>The Site cannot and does not contain [medical/legal/fitness/other] advice. The [medical/legal/fitness/other] information is provided for general informational and educational purposes only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage you to consult with the appropriate professionals. We do not provide any kind of [medical/legal/fitness/other] advice. The use or reliance on any information contained on this site or our mobile application is solely at your own risk.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Personal Responsibility</Text>
          <Text>You acknowledge you are using our Site and mobile application voluntarily and that any choices, actions, and results now and in the future are solely your responsibility. We will not be liable to you or any other party for any decision made or action taken in reliance on the information given on the Site or our mobile application.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Affiliate Disclaimer</Text>
          <Text>The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases made by you on the affiliate website using such links.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Testimonials Disclaimer</Text>
          <Text>The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and opinions of such users. However, the experiences are personal to those particular users and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Limitation of Liability</Text>
          <Text>We may update our Disclaimer from time to time in order to reflect changes to our practices or for other operational, legal, or regulatory reasons.</Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Contact Us</Text>
          <Text mb={'5px'}>If you have any questions about this Disclaimer, you can contact us:</Text>
          <UnorderedList pl={'20px'}>
            <ListItem>By email: admin@inwise.ai</ListItem>
            <ListItem>By visiting this page on our website: http://inwise.ai/contact</ListItem>
          </UnorderedList>
        </Flex>
      </Flex >
      <FooterNavBar />
    </Flex >
  );
};

export default PrivacyPage;
