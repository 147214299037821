import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const MicrosoftLoginCallback = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const msOauthCodeExchange = "https://appwise-functions.azurewebsites.net/api/login-microsoft-code?code=4AhQHCvyNbIVFeOewy8LR4K0q5OEFOg60aEhoCZs7vAeAzFu29iJHQ%3D%3D";
  const microsoftLoginUrl = "https://appwise-functions.azurewebsites.net/api/login-microsoft-authentication?code=H4RYadml7IHyeUKeMepjpyiPxTWRCnPynbLHIKgsC0tFAzFuS3BVgw%3D%3D";

  useEffect(() => {
    const exchangeCodeForToken = async (code: string) => {
      try {
        const response = await fetch(msOauthCodeExchange, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code }),
        });

        if (!response.ok) throw new Error('Failed to exchange code for token');

        const data = await response.json();
        console.log('Tokens:', data);

        const accessToken = data.accessToken;
        const refreshToken = data.refreshToken;
        const expiresIn = data.expiresIn;
        const name = data.name;
        const email = data.email;

        const response2 = await fetch(microsoftLoginUrl, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ accessToken, refreshToken, expiresIn, name, email })
        });
        const data2 = await response2.json();
        localStorage.setItem("accessToken", data2.loggedUser.accessToken);
        localStorage.setItem("wiseCompanyId", data2.loggedUser.wiseCompanyId);
        localStorage.setItem("loggedUserName", data2.loggedUser.userFullName);
        localStorage.setItem("loggedWithGoogle", data2.loggedUser.email);
        localStorage.setItem("lastFetch", String(new Date()));
        window.location.replace("/");

      } catch (error) {
        localStorage.clear();
        alert('Failed to log in. Please try again.');
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');

    if (code) {
      exchangeCodeForToken(code);
    } else {
      setError('Authorization code not found.');
      setLoading(false);
    }
  }, [history]);

  if (loading) return <div>Processing login...</div>;
  if (error) return <div>{error}</div>;

  return null;
};

export default MicrosoftLoginCallback;
