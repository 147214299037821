import React from 'react';
import { Flex, Link, Text, Image, ListItem, OrderedList, UnorderedList } from '@chakra-ui/react';
import WiseLogo2 from 'assets/svg/wise-logo-2.svg';
import FooterNavBar from './components/InWiseFooter';

let navbarBg = 'none';
let navbarShadow = 'initial';

const DocsPage = () => {
  return (
    <Flex justifyContent={'space-between'} flexDir={'column'} alignItems={'center'}>
      <Flex
        top='16px'
        background={navbarBg}
        boxShadow={navbarShadow}
        borderRadius='15px'
        px='16px'
        py='22px'
        mx='auto'
        width='1044px'
        maxW='90%'
        alignItems='center'
        zIndex='3'>
        <Flex w='100%' justifyContent={{ sm: 'start', lg: 'space-between' }}>
          <Link href='/'>
            <Image src={WiseLogo2} alt='Wise Logo' h='72px' w='92px' />
          </Link>
        </Flex>
      </Flex>
      <Flex alignItems={'flex-start'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} flexDir='column' pl={'150px'}>
        <Flex>
          <Text fontSize={'50px'}>InWise.ai Zoom Meeting Assistant</Text>
        </Flex>
      </Flex>
      <Flex flexDir={'column'} justifyContent={'flex-start'} mt='50px' ml='50px' w={'100%'} pl={'150px'} pb={'100px'}>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Usage</Text>
          <Text>
            To use the InWise.ai Zoom Meeting Assistant, follow these steps:
          </Text>
          <OrderedList>
            <ListItem>Register an Account: Create an account on InWise.ai.</ListItem>
            <ListItem>Consent: Agree to our privacy policy and acknowledge how we collect and use your data.</ListItem>
            <ListItem>
              <Text>Transcription Bot: Upon consent, transcription bots (powered by Recall.ai) will be available for your meetings.</Text>
              <UnorderedList pl={'20px'}>
                <ListItem>Allow Bot: You will have the option to permit the transcription bot to join your meeting.</ListItem>
                <ListItem>Kick Out Bot: You can remove the transcription bot from your meeting at any time.</ListItem>
              </UnorderedList>
            </ListItem>
            <ListItem>Transcript Processing: After the transcription bot leaves a meeting, the transcript will be sent to InWise.ai for processing.</ListItem>
            <ListItem>Access Results: View the processed transcript and results on your InWise.ai account.</ListItem>
          </OrderedList>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Installation</Text>
          <Text>
            No installation is required for the InWise.ai Zoom app.
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Uninstallation</Text>
          <Text>
            No uninstallation is necessary for the InWise.ai Zoom app.
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Troubleshooting</Text>
          <Text>
            For any issues, please contact our support team using the information provided on our website.
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Privacy and Security</Text>
          <Text>
            We prioritize your privacy and security. For more details, please refer to our Privacy Policy
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>FAQs</Text>
          <Text fontWeight={"semibold"} mb={'5px'}>
            Q: How do I allow the transcription bot into my meeting?
          </Text>
          <Text mb={'10px'}>
            A: When the bot requests to join your meeting, you will see a prompt asking for permission. Simply click 'Admit'. If you are the host of the meeting, the bot will also ask you for permission to record the meeting. Click 'Allow Recording'
          </Text>
          <Text fontWeight={"semibold"} mb={'5px'}>
            Q: Can I manually start and stop transcription?
          </Text>
          <Text mb={'10px'}>
            A: Yes, you can control the transcription bot by using the options to allow or kick out the bot at any time during the meeting.
          </Text>
        </Flex>
        <Flex flexDir={'column'} mb={'20px'}>
          <Text fontWeight={"bold"} mb={'10px'}>Support</Text>
          <Text>
            If you encounter any problems or have questions, please visit our support page
          </Text>
        </Flex>
      </Flex >
      <FooterNavBar />
    </Flex>
  );
};

export default DocsPage;
