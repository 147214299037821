import { useState, useEffect } from 'react';
import {
  Text, useColorModeValue, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, ListItem, UnorderedList
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import { CallIcon, MailIcon, TaskIcon } from 'components/icons/Icons';
import { Deal, GetGlobalActionsResponse } from 'views/deals/Deal';

// TODO: do we want to define this map in 'components/icons/Icons'?
const iconMap: { [x: string]: any; } = {
  'call': CallIcon,
  'mail': MailIcon,
  'task': TaskIcon,
};

const getGlobalActionsToDoUrl = "https://appwise-functions.azurewebsites.net/api/get-actionitems-list?code=-D9uif06mqrAuYJbN91yOexsIml-ZVlbTpLILzoY0gf3AzFuiIKFMg%3D%3D";

export default function TodoList() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const headingColor = useColorModeValue('navy.800', 'white');
  const [dealsWithActions, setDealsWithActions] = useState<Deal[]>()
  useEffect(() => {
    if (dealsWithActions?.length) {
      return;
    }
    fetch(getGlobalActionsToDoUrl, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      // TODO add JWT
      body: JSON.stringify({
        wiseCompanyId: localStorage.getItem("wiseCompanyId")
      })
    })
      .then(res => res.json())
      .then((response: GetGlobalActionsResponse) => {
        console.debug(response.message);
        setDealsWithActions(response.deals);
      })
      .catch((error: Error) => {
        console.log(error);
      })
  }, []);
  if (!dealsWithActions) return null;
  return (
    <Card p={4} w='100%' bg='white' pt={6} h='max-content'>
      <Text color={textColor} fontSize='2xl' fontWeight='bold' mb='10px' ml='15px'>
        Actions to do today
      </Text>
      <Accordion w='100%' allowMultiple>
        {dealsWithActions.map((deal: Deal, index: any) => (
          <AccordionItem key={index} borderTopColor={index == 0 ? 'transparent' : 'default'}>
            <h2>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left'>
                  {deal.name}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <UnorderedList fontSize={'16px'}>
                {deal.actionItems?.map((item: any, index) => (
                  <ListItem key={index} ml={2}>{item.content.item}</ListItem>
                ))}
              </UnorderedList>

            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Card>
  );
}
