import { Button, Flex, HStack, Image, Input, Link, Stack, Text } from "@chakra-ui/react";
import { Link as RouterLink } from 'react-router-dom';
import WiseLogo2 from 'assets/svg/wise-logo-2.svg';


export default function NavBar() {
  let mainText = '#0F738C';
  let navbarBg = 'none';
  let navbarShadow = 'initial';

  return (
    <Flex
      top='16px'
      background={navbarBg}
      boxShadow={navbarShadow}
      borderRadius='15px'
      px='16px'
      py='22px'
      mx='auto'
      width='1044px'
      maxW='90%'
      alignItems='center'
      zIndex='3'>
      <Flex w='100%' justifyContent={{ sm: 'start', lg: 'space-between' }}>
        <Link href='/'>
          <Image src={WiseLogo2} alt='Wise Logo' h='72px' w='92px' />
        </Link>
        <HStack display={{ sm: 'none', lg: 'flex' }} spacing='40px'>
          <Stack
            display='none'
            direction='row'
            spacing='4px'
            alignItems='center'
            color='#fff'
            fontWeight='bold'
            cursor='pointer'
            position='relative'>
            <Text fontSize='sm' color={mainText}>
              Why InWise
            </Text>
          </Stack>
          <Stack
            direction='row'
            spacing='4px'
            alignItems='center'
            color='#fff'
            fontWeight='bold'
            cursor='pointer'
            position='relative'>
            <RouterLink to="/about">
              <Text fontSize='sm' color={mainText}>
                About Us
              </Text>
            </RouterLink>
          </Stack>
          <Stack
            direction='row'
            spacing='4px'
            alignItems='center'
            color='#fff'
            fontWeight='bold'
            cursor='pointer'
            position='relative'>
            <RouterLink to="/contact">
              <Text fontSize='sm' color={mainText}>
                Contact Us
              </Text>
            </RouterLink>
          </Stack>
        </HStack>
        <RouterLink to='/login'>
          <Button display={window.location.pathname != '/login' ? 'block' : 'none'} fontSize='sm' variant='outline' borderWidth={3} fontWeight='500' w='200px' h='40px' >Sign In</Button>
        </RouterLink>
      </Flex>
    </Flex>
  );
}
