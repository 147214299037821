import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Avatar,
  Box,
  Flex,
  Text,
  Input,
  useColorModeValue,
  Stack,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  Icon,
} from '@chakra-ui/react';
import { CompanySearchResult, createProspectResponse, ProspectSearchResult } from 'views/prospects/Prospect';
import Card from 'components/card/Card';
import { BackButton, NextButton, TableRowWithRadio } from './common';
import { SearchIcon } from 'components/icons/Icons';
import { createDealResponse } from 'views/deals/Deal';
import { on } from 'events';

const searchPeopleDataURL = "https://inwise-node-functions2.azurewebsites.net/api/pdl-enrichment-search?code=PQdgvBwUMH-j9Fq4B2zVljPVNaMMCdz4pqKYztHEmytSAzFunx1HDA%3D%3D";
const createDealURL = "https://inwise-node-functions2.azurewebsites.net/api/create_deal_test_2?code=3QlD8L3t435R29h-56EPtrdEga4fyNoZm0l9ImdC5EtMAzFuGN5EvA%3D%3D"
const createProspectUrl = "https://inwise-node-functions2.azurewebsites.net/api/prospect-create?code=8PrrZmNXRmWVZQs8do30Zz5DeTAm9DfO0xVozvsbQwVgAzFu4SwJjw%3D%3D";
const prospectSummarizeURL = "https://inwise-node-functions2.azurewebsites.net/api/generate-prospect-summarize?code=hSjOr1L5-uI1Fd_tSWUp4A4mcOlDpImxFJ84rqcu6A8SAzFuoyMjoA%3D%3D"
const prospectClassificationURL = "https://inwise-node-functions2.azurewebsites.net/api/generate-prospect-classification?code=lOvUdKjKR4gMQh-laQnAI4nxXyfLpwJZROTH_eUSUV34AzFuV5QfUg%3D%3D"
const accountSummarizeURL = "https://inwise-node-functions2.azurewebsites.net/api/company-summarize?code=EphVZzR043ZeyxgNddEPW7ucnYkgYl8q3nYTYXZN9YaZAzFu1VpBaQ%3D%3D"


export default function SelectProduct(props: {
  prospect: ProspectSearchResult,
  variant: 'createDeal' | 'addProspect',
  companyResult?: CompanySearchResult,
  onReturn: () => Promise<void>;
  onSearchCompany: () => void;
  onLoading: (loading: boolean) => void;
}) {
  const textColor = useColorModeValue('navy.700', 'white');
  const borderColor = useColorModeValue('secondaryGray.400', 'whiteAlpha.100');
  const { prospect, variant, companyResult, onReturn, onSearchCompany, onLoading } = props;
  const [updatedProspect, setUpdatedProspect] = useState(prospect);

  // from prospect.experience, filter out experiences that have a company id because we know these are specific companies in peopleData
  const previousWork = [
    ...prospect.experience
      .filter(exp => exp?.company?.id && exp.company.id === prospect.job_company_id),
    ...prospect.experience
      .filter(exp => exp?.company?.id && exp.company.id !== prospect.job_company_id)
      .reduce((acc, exp) => {
        if (!acc.some(item => item.company.id === exp.company.id)) {
          acc.push(exp);
        }
        return acc;
      }, [])].sort((a, b) => {
        const dateA = new Date(a.start_date);
        const dateB = new Date(b.start_date);
        return dateB.getTime() - dateA.getTime();
      });

  const [selectedCompany, setSelectedCompany] = useState<CompanySearchResult | null>(companyResult ?? previousWork?.length > 0 ? previousWork[0].company : null);
  const [displayCompanyFromSearch, setDisplayCompanyFromSearch] = useState(false);
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdatedProspect((prevProspect) => ({
      ...prevProspect,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (companyResult) {
      setDisplayCompanyFromSearch(companyResult !== null);
      setSelectedCompany(companyResult);
    }
  }, [companyResult])

  const history = useHistory();

  async function fetchData(url: string, body: string) {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      if (response.ok) {
        return await response.json();
      } else {
        console.error('Error fetching data:', response.status, response.statusText);
        return null;
      }
    } catch (error) {
      console.error('Error during fetch:', error);
      return null;
    }
  }

  const handleCreateDeal = async () => {

    if (!selectedCompany) { return; }
    let companySearchResult;
    onLoading(true);
    try {
      // Fetch company data
      companySearchResult = (await fetchData(searchPeopleDataURL, JSON.stringify({ command: 'company', id: selectedCompany.id })))[0];
      if (!companySearchResult) { return; }
      // create deal with company and prospect
      const data = await fetchData(createDealURL, JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), company: companySearchResult, prospect: updatedProspect }));
      if (!data) { return; }
      const newDealId = (data as createDealResponse).newDealResponse.insertedId
      const newProspectId = (data as createDealResponse).newProspectResponse.insertedId;
      const accountId = (data as createDealResponse).accountId;
      // summarize prospect
      await fetchData(prospectSummarizeURL, JSON.stringify({ prospectId: newProspectId }));
      // classify prospect
      await fetchData(prospectClassificationURL, JSON.stringify({ prospectId: newProspectId }));
      // summarize company
      await fetchData(accountSummarizeURL, JSON.stringify({ companyId: accountId }));

      history.push(`/deals/overview/${newDealId}`); // Above or down?
    }
    catch (error) { onLoading(false); console.error('Error during fetch:', error) }
  }

  const handleCreateProspect = async () => {
    console.log(prospect, localStorage.getItem('dealId'));
    onLoading(true);
    try {
      // create prospect and add to existing deal
      const data = await fetchData(createProspectUrl, JSON.stringify({ wiseCompanyId: localStorage.getItem("wiseCompanyId"), prospect: prospect, dealId: localStorage.getItem('dealId') }));
      if (!data) { return; }
      const newProspectId = (data as createProspectResponse).newProspectId;
      // summarize prospect
      await fetchData(prospectSummarizeURL, JSON.stringify({ prospectId: newProspectId }));
      //what about classify prospect?
      history.push(`/prospects/overview/${newProspectId}`);
    }
    catch (error) { onLoading(false); console.error('Error during fetch:', error) }
  }

  const editProspectInfo = <Flex direction='column' my='5px'>
    <Text color={textColor} fontSize='md' fontWeight='400'>
      Edit information for {prospect.first_name}:
    </Text>
    <Stack direction='column' spacing='10px' mt='5px'>
      <Input
        placeholder="Full Name"
        name="full_name"
        defaultValue={updatedProspect.full_name}
        fontSize={'sm'}
        onChange={handleInputChange} />
      <Input
        placeholder="Job Title"
        name="job_title"
        defaultValue={updatedProspect.job_title}
        fontSize={'sm'}
        onChange={handleInputChange} />
      <Input
        placeholder="Email"
        name="email"
        defaultValue={updatedProspect.email}
        fontSize={'sm'}
        onChange={handleInputChange} />
      <Input
        placeholder="Phone Number"
        name="phone_number"
        defaultValue={updatedProspect.phone_number}
        fontSize={'sm'}
        onChange={handleInputChange} />
    </Stack>
  </Flex>;


  // dropdown for selecting company
  function handleCompanyChange(value: any) {
    const exp = previousWork.find(exp => exp.company.id === value)
    setSelectedCompany(exp?.company ?? null);
  }

  function removeCompanyResult() {
    setDisplayCompanyFromSearch(false);
    setSelectedCompany(previousWork.length > 0 ? previousWork[0].company : null);
  }

  return (
    <Card p='30px' m='10px'>
      <Text color={textColor} fontSize='xl' fontWeight='700' mb='5px'>{variant == 'createDeal' ? `Create deal with ${prospect.first_name}` : `Add ${prospect.full_name} as a prospect`}</Text>
      <Flex direction='column' justify='flex-start' h={'100%'}>
        <Box>
          <Flex mt='10px' mb='10px' pb='10px' alignItems='left' borderBottom='1px solid'
            borderColor={borderColor}>
            <Avatar h='48px' w='48px' src={prospect.pictureLink} me='20px' />
            <Box>
              <Text color={textColor} fontSize='md' fontWeight='700'>
                {prospect.job_title}{prospect.job_title ? ', ' : ''}{prospect?.job_company_name ?? prospect.experience[0]?.company?.name ?? ''}
              </Text>
              {prospect.location_name && <Text color='secondaryGray.600' fontSize='sm' fontWeight='400'>
                {prospect.location_name.split(',').slice(0, 2).join(', ')}
              </Text>}
            </Box>
          </Flex>
        </Box>
        {variant == 'createDeal' && <Flex direction={'column'} pb={'10px'}>
          <Text color={textColor}>
            Company:
          </Text>
          {displayCompanyFromSearch ?
            <Tag size={'lg'} key={companyResult.id} variant='subtle' justifyContent={'space-between'}>
              <TagLabel>{companyResult.name}</TagLabel>
              <TagCloseButton onClick={removeCompanyResult} />
            </Tag>
            :
            <Flex>
              {previousWork.length > 0 ? <Select onChange={(e) => handleCompanyChange(e.target.value)} value={selectedCompany ? selectedCompany.id : null} size={'md'} fontSize={'sm'}>
                {previousWork.map((exp, index) => (<option key={index} value={exp.company.id}>{exp.company.name}</option>))}
              </Select> :
                <Text color={textColor} ml='10px' w={'100%'} size={'md'} pt={'5px'} >No companies listed. Try searching</Text>}
              <Flex as="button" onClick={() => onSearchCompany()} color={'brand.500'} ml='4px' p={'5px'} >
                <Icon as={SearchIcon} w="25px" h="25px" />
              </Flex>
            </Flex>}
        </Flex>}
        {editProspectInfo}
      </Flex>
      <Flex justify='space-between' mt='24px'>
        <BackButton onClick={onReturn} mr='10px' />
        <NextButton
          isDisabled={!(variant == 'addProspect' || selectedCompany)}
          onClick={variant == 'addProspect' ? handleCreateProspect : handleCreateDeal}
          buttonText={variant == 'addProspect' ? 'Add to Deal' : 'Create Deal'} ml='10px' />
      </Flex>
    </Card>
  );
}
